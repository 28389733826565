import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { appConstants } from '../../../shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateCisternaInput,
  CreateCisternaMutation,
  CreateNotificationInput,
  Document,
  Cisterna,
  UpdateCisternaInput,
} from '../../../app-sync.service';
import { DocumentsService } from '../../documents/documents.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { MasterService } from '../../master/master.service';
import { SettingsService } from '../../settings/settings.service';
import { UsersService } from '../../users/users.service';
import { ValidatorsService } from '../../../shared/services/validators.service';
import { CisternasService } from '../cisternas.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-cisterna-edit',
  templateUrl: './cisterna-edit.component.html',
  styleUrls: ['./cisterna-edit.component.css'],
})
export class CisternaEditComponent implements OnInit, OnDestroy {
  entityName: string = 'Cisterna';
  selectedCisterna: Cisterna | undefined = undefined;
  cisternaIdentification: string = '';
  equipType: string = '';
  equipTypeToDisplay: string = '';
  currentYear: number = new Date().getFullYear();
  editMode: boolean = true;
  archiveMode: boolean = false;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingCisterna: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  selectedCenter: string = '';
  cisternaForm: FormGroup;
  cisternaId: string = '';
  cisternaIsOld: boolean = false;
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';
  cisternaVolumeSelected: string = '';
  cisternasVolumes: { valueId: string; valueToDisplay: string }[] = [];
  cisternaContainersNumberSelected: string = '';
  cisternasContainersNumbers: { valueId: string; valueToDisplay: string }[] =
    [];
  allCisternasContainersNumbers: {
    [index: string]: { valueId: string; valueToDisplay: string }[];
  } = {};
  cisternasCapacities: { valueId: string; valueToDisplay: string }[] = [];

  get documentsControl() {
    return (<FormArray>this.cisternaForm.get('documents')).controls;
  }

  get disableSubmit() {
    return (
      this.cisternaForm.invalid ||
      this.cisternaForm.pristine ||
      this.cisternaForm.untouched
    );
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private cisternasService: CisternasService,
    private formsService: FormsService,
    private editionsService: EditionsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.cisternaForm = new FormGroup({
      carrier: new FormControl(null),
      licensePlate: new FormControl(null),
      brand: new FormControl(null),
      model: new FormControl(null),
      year: new FormControl(null),
      color: new FormControl(null),
      type: new FormControl(null),
      capacity: new FormControl(null),
      subBusiness: new FormControl(null),
      center: new FormControl(null),
      gpsImei: new FormControl(null),
      cameraImei: new FormControl(null),
      forMining: new FormControl(null),
      hasRanchAccreditation: new FormControl(null),
      isSpot: new FormControl(null),
      engineType: new FormControl(null),
      bulkheadType: new FormControl(null),
      hasPump: new FormControl(null),
      documents: this.formsService.documentsFormArrayInitialization(),
      volume: new FormControl(null),
      containersNumber: new FormControl(null),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit() {
    this.isGettingCisterna = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.archiveMode = this.archivesService.getArchiving();
    this.selectedCisterna = this.cisternasService.getSelectedCisterna();
    this.cisternaIdentification = this.commonsService.getIdentification(
      this.selectedCisterna.sapId!,
      this.selectedCisterna.licensePlate
    );

    this.equipType = this.master['CISTERNAS#TYPES'][0].valueId;
    this.equipTypeToDisplay =
      this.masterDisplayMap['CISTERNAS#TYPES'][this.equipType];

    this.masterService.setValidVolumes('CISTERNA');
    this.cisternasVolumes = this.masterService.getValidVolumes('CISTERNA');
    this.allCisternasContainersNumbers =
      this.masterService.getValidContainersNumber('CISTERNA');

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de cisterna
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.cisternaId = params['id'];

      this.initCisternaForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'CISTERNA') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('CISTERNA');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#CISTERNA#${this.cisternaId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingCisterna = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un cisterna.
   * @private
   */
  private initCisternaForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    const editingOrArchiving: boolean = this.editMode || this.archiveMode;
    let initialCisterna: Cisterna = {
      ...appConstants.cisterna.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let cisternaDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialCisterna.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';
    // El tipo de equipo es un valor fijo.
    initialCisterna.type = this.equipType;

    if (editingOrArchiving) {
      // En modo de Edición: cargamos los valores de los campos
      // según la cisterna escogida.
      // En modo Archivar: cargamos los valores de los campos según
      // la cisterna archivada (que sigue siendo la seleccionada).
      initialCisterna = this.selectedCisterna!;
      const selectedCisternaDocuments: Document[] =
        this.documentsService.getDocuments();
      const capacityAsArray: string[] = initialCisterna.capacity
        .replace(`${this.business}_`, '')
        .split('#');
      this.cisternaVolumeSelected = capacityAsArray[0];
      this.cisternasContainersNumbers =
        this.allCisternasContainersNumbers[this.cisternaVolumeSelected];
      const containerCount: number = capacityAsArray.length - 1;
      this.cisternaContainersNumberSelected =
        containerCount === 0 ? '' : containerCount.toString();
      this.setCisternasCapacities();

      const cisternaAge: number = this.currentYear - initialCisterna.year;
      let cisternaHasRecertificationOfManufacturing: boolean = false;

      let documentMasterValueIdsList: string[] = [];
      cisternaDocuments.removeAt(0);
      for (let document of selectedCisternaDocuments) {
        cisternaDocuments.push(
          this.formsService.createDocumentControlFromDocument(
            document,
            !this.archiveMode
          )
        );
        documentMasterValueIdsList.push(document.masterValueId);
        // Verificamos si la cisterna tiene el Re-certificado de Fabricación
        if (
          document.masterValueId ===
          `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
        ) {
          cisternaHasRecertificationOfManufacturing = true;
        }
      }

      // Si la edad de la cisterna es >= appConstants.cisterna.ageToBeConsideredOld
      // y la cisterna NO tiene asignado un documento Re-certificado de Fabricación
      // se agrega al formulario.
      if (
        cisternaAge >= appConstants.cisterna.ageToBeConsideredOld &&
        !cisternaHasRecertificationOfManufacturing
      ) {
        // Agregamos el Re-certificado de Fabricación
        const index = this.master['CISTERNAS#DOCUMENT_TYPES'].findIndex(
          (document: any): boolean => {
            return (
              document.valueId ===
              `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
            );
          }
        );
        const document = this.master['CISTERNAS#DOCUMENT_TYPES'][index];

        cisternaDocuments.push(
          this.formsService.createDocumentControlFromMaster(document)
        );
        this.cisternaIsOld = true;
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      cisternaDocuments = this.setAdditionalDocumentsFormGroup(
        initialCisterna.center,
        cisternaDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      cisternaDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.cisternaForm = new FormGroup({
      carrier: new FormControl(
        { value: initialCisterna.carrier.carrierId, disabled: this.editMode },
        Validators.required
      ),
      licensePlate: new FormControl(
        {
          value: initialCisterna.licensePlate,
          disabled: this.disableLicensePlate(),
        },
        [
          Validators.required,
          this.validatorsService.licensePlatePatternValidator(this.business),
        ],
        [this.validatorsService.licensePlateInUseValidator()]
      ),
      brand: new FormControl(
        { value: initialCisterna.brand, disabled: this.editMode },
        Validators.required
      ),
      model: new FormControl(
        { value: initialCisterna.model, disabled: this.editMode },
        Validators.required
      ),
      year: new FormControl(
        {
          value: initialCisterna.year === 0 ? null : initialCisterna.year,
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern(appConstants.regex.year)]
      ),
      color: new FormControl(
        { value: initialCisterna.color, disabled: this.editMode },
        Validators.required
      ),
      type: new FormControl(
        { value: initialCisterna.type, disabled: this.editMode },
        Validators.required
      ),
      volume: new FormControl(
        { value: this.cisternaVolumeSelected, disabled: this.editMode },
        Validators.required
      ),
      containersNumber: new FormControl(
        {
          value: this.cisternaContainersNumberSelected,
          disabled: this.editMode,
        },
        Validators.required
      ),
      capacity: new FormControl(
        { value: initialCisterna.capacity, disabled: this.editMode },
        Validators.required
      ),
      center: new FormControl(
        { value: initialCisterna.center, disabled: this.editMode },
        Validators.required
      ),
      subBusiness: new FormControl(
        { value: initialCisterna.subBusiness, disabled: this.editMode },
        Validators.required
      ),
      gpsImei: new FormControl({
        value: initialCisterna.gpsImei,
        disabled: this.editMode,
      }),
      cameraImei: new FormControl({
        value: initialCisterna.cameraImei,
        disabled: this.editMode,
      }),
      forMining: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialCisterna.forMining
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      hasRanchAccreditation: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialCisterna.hasRanchAccreditation
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      isSpot: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialCisterna.isSpot
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      engineType: new FormControl(
        { value: initialCisterna.engineType, disabled: this.editMode },
        Validators.required
      ),
      bulkheadType: new FormControl(
        { value: initialCisterna.bulkheadType, disabled: this.editMode },
        Validators.required
      ),
      hasPump: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialCisterna.hasPump
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      documents: cisternaDocuments,
    });
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece la cisterna.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let cisternaDocuments =
      this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['CISTERNAS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    cisternaDocuments.removeAt(0);
    for (let document of documentTypes) {
      // Se excluye el Re-certificado de Fabricación, ya que se agrega según el año de fabricación
      if (
        document.valueId !==
        `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
      ) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        cisternaDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return cisternaDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece la cisterna.
   * @param {FormArray} cisternaDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el cisternaDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    cisternaDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['CISTERNAS#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    const business: string = this.usersService.business.value.toUpperCase();
    for (let document of documentTypes) {
      // Se excluye el Re-certificado de Fabricación, ya que se agrega según el año de fabricación
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (
        document.valueId !==
          `${business}_${appConstants.document.codes.recertification_of_manufacturing}` &&
        documentMasterValueIdsList.indexOf(document.valueId) === -1
      ) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        cisternaDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return cisternaDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string }center Centro al que pertenece la cisterna.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onCisternaCenterChange(): void {
    if (this.selectedCenter === this.cisternaForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.cisternaForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const cisternaDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.cisternaForm.removeControl('documents');
      this.cisternaForm.setControl('documents', cisternaDocuments);
    }
  }

  /**
   * Ejecuta un flujo para agregar o remover el Re-certificado de fabricación
   * a la lista de documentos dependiendo de la edad de la cisterna.
   */
  onCisternaYearChange(): void {
    // En caso de que la cisterna haya sido
    // fabricado hace 7 años o más, el
    // Re-certificado de Fabricación será solicitado.
    if (
      this.currentYear - this.cisternaForm.get('year')?.value >=
      appConstants.cisterna.ageToBeConsideredOld
    ) {
      this.onCisternaIsOld();
    } else {
      if (this.cisternaIsOld) {
        // Si antes se había seleccionado un año que hacía
        // que la edad de la cisterna fuese >= appConstants.cisterna.ageToBeConsideredOld
        // la variable cisternaIsOld será verdadera
        // Removemos el control de formulario
        // asociado al Re-certificado de Fabricación
        this.onCisternaIsYoung();
      }
    }
  }

  /**
   * Agregar el Re-certificado de fabricación a la lista de documentos
   * y define la variable cisternaIsOld = True.
   * @private
   */
  private onCisternaIsOld(): void {
    if (!this.cisternaIsOld) {
      // Agregamos el Re-certificado de Fabricación
      const index = this.master['CISTERNAS#DOCUMENT_TYPES'].findIndex(
        (document: any): boolean => {
          return (
            document.valueId ===
            `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
          );
        }
      );
      const document = this.master['CISTERNAS#DOCUMENT_TYPES'][index];

      (<FormArray>this.cisternaForm.get('documents')).push(
        this.formsService.createDocumentControlFromMaster(document)
      );
      this.cisternaIsOld = true;
    }
  }

  /**
   * Remueve el Re-certificado de fabricación a la lista de documentos
   * y define la variable cisternaIsOld = False.
   * @private
   */
  private onCisternaIsYoung(): void {
    let documentFormArray = <FormArray>this.cisternaForm.get('documents');
    const index: number = (<Array<any>>documentFormArray.value).findIndex(
      (document: any): boolean => {
        return (
          document.documentMasterValueId ===
          `${this.business}_${appConstants.document.codes.recertification_of_manufacturing}`
        );
      }
    );
    documentFormArray.removeAt(index);
    this.cisternaForm.setControl('documents', documentFormArray);
    this.cisternaIsOld = false;
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeDocumentFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      (<FormArray>this.cisternaForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Actualiza la variable cisternaVolumeSelected cuyo valor
   * es el volumen de la cisterna seleccionado en el formulario.
   */
  onCisternaVolumeChange(): void {
    this.cisternaVolumeSelected = this.cisternaForm.get('volume')?.value;
    // Configuración de opciones de compartimentos.
    this.cisternasContainersNumbers =
      this.allCisternasContainersNumbers[this.cisternaVolumeSelected];
    // Reseteo de compartimentos seleccionados.
    this.cisternaContainersNumberSelected = '';
    this.cisternaForm
      .get('containersNumber')
      ?.reset(this.cisternaContainersNumberSelected);
    // Reseteo de capacidad.
    this.cisternaForm.get('capacity')?.reset('');
  }

  /**
   * Actualiza la variable cisternasContainersNumberSelected cuyo valor
   * es el número de contenedores de la cisterna seleccionado en el formulario.
   */
  onCisternaContainersNumberChange(): void {
    this.cisternaContainersNumberSelected =
      this.cisternaForm.get('containersNumber')?.value;
    // configura las opciones de capacidades
    this.setCisternasCapacities();
    // Reseteo de capacidad.
    this.cisternaForm.get('capacity')?.reset('');
  }

  /**
   * Configura la lista de capacidades compatibles
   * con las selecciones del usuario.
   * Deben estar definidos tanqueVolumeSelected
   * y tanqueContainersNumberSelected
   * @private
   */
  private setCisternasCapacities() {
    this.cisternasCapacities = [];
    for (const capacity of this.master['CISTERNAS#CAPACITIES']) {
      const hasTheVolumen: boolean = capacity.valueId.startsWith(
        `${this.business}_${this.cisternaVolumeSelected}`
      );
      const hasTheContainerNumber: boolean =
        (capacity.valueId.split('#').length - 1).toString() ===
        this.cisternaContainersNumberSelected;

      if (hasTheVolumen && hasTheContainerNumber) {
        this.cisternasCapacities.push(capacity);
      }
    }
  }

  /**
   * Retorna la fuente de la imagen según la capacidad seleccionada.
   * @return {string} Ruta a la imagen
   */
  capacityImage(): string {
    let srcPath: string = '../../../../assets/images/capacities/capacity-';
    if (this.cisternaForm.get('capacity')?.value !== '') {
      let imageId: string = this.cisternaForm.get('capacity')?.value;
      srcPath += `${imageId.replace(this.business + '_', '')}`;
    } else if (this.cisternaContainersNumberSelected !== '') {
      srcPath += `${this.cisternaVolumeSelected}m3-${this.cisternaContainersNumberSelected}c`;
    } else if (this.cisternaVolumeSelected !== '') {
      srcPath += `${this.cisternaVolumeSelected}m3-1c`;
    } else {
      srcPath += 'unknown';
    }
    return (srcPath + '.jpg').replaceAll('#', '%23');
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company: string = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.cisternaForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /**
   * Determina si se debe deshabilitar el input
   * para la patente.
   */
  private disableLicensePlate(): boolean {
    return (
      this.editMode ||
      (this.archiveMode && !this.archivesService.hasToChangeID())
    );
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un cisterna.
   */
  async onSubmit(): Promise<void> {
    console.log(' onSubmit @ cisterna-edit.component ');
    console.log(this.cisternaForm.value);
    const newCisterna = this.cisternaForm.value;
    const documentList: DocumentFromForms[] =
      this.cisternaForm.value.documents.slice();

    if (this.disableLicensePlate()) {
      // Si la patente fue deshabilitada se asigna acá.
      newCisterna['licensePlate'] = this.selectedCisterna!.licensePlate;
    }

    console.log('newCisterna', newCisterna);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza la cisterna.
      let cisternaEdited: boolean = false;
      documentList.forEach((document: any): void => {
        cisternaEdited = cisternaEdited || Boolean(document.documentSourceFile);
      });
      if (!cisternaEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar la cisterna',
          'danger'
        );
        return;
      }

      // Nota: En el modo edición los ID fueron fijados al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando cisterna: ' + this.cisternaId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo edición, solo pueden editarse los documentos
      // Sin embargo, la cisterna vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia en estatus de la cisterna
      const updateCisternaInput: UpdateCisternaInput = {
        cisternaId: this.cisternaId,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .UpdateCisterna(updateCisternaInput)
        .then(() => {
          // 2. Se cargan los documentos de la cisterna, si aplica.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'CISTERNA',
            this.cisternaId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar cisterna ${this.cisternaId}`
          );
        });
    } else {
      // Si es un flujo de archivado,
      // Se elimina primero la entidad anterior
      // para evitar conflictos si es la misma patente.
      if (this.archiveMode) {
        // 1. Eliminar entidad anterior
        // (en este punto sigue como la que está seleccionada)
        await this.cisternasService.deleteSelectedCisterna(false);

        // 2. Liberamos el modo de archivado
        this.archivesService.setArchiving(false);
      }

      // En el modo crear los ID se toman desde el formulario.
      this.cisternaId = newCisterna.licensePlate.toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando cisterna: ${this.cisternaId}`,
        'info'
      );

      // En modo creación o archivo:
      // 1. Se crea una nueva cisterna.
      const createCisternaInput: CreateCisternaInput = {
        cisternaId: this.cisternaId,
        brand: newCisterna.brand,
        licensePlate: this.cisternaId, // La licencia corresponde con el ID
        color: newCisterna.color,
        model: newCisterna.model.toUpperCase(),
        year: newCisterna.year,
        type: newCisterna.type,
        capacity: newCisterna.capacity,
        business: this.business,
        subBusiness: newCisterna.subBusiness,
        center: newCisterna.center,
        originalCenter: newCisterna.center,
        blockingMotives: [],
        gpsImei: newCisterna.gpsImei,
        cameraImei: newCisterna.cameraImei,
        forMining: newCisterna.forMining === 'true',
        hasRanchAccreditation: newCisterna.hasRanchAccreditation === 'true',
        isSpot: newCisterna.isSpot === 'true',
        engineType: newCisterna.engineType,
        bulkheadType: newCisterna.bulkheadType,
        hasPump: newCisterna.hasPump === 'true',
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`, // Una nueva cisterna debe ser aprobada
        company: newCisterna.carrier,
        carrierCisternasCarrierId: newCisterna.carrier,
        updatedBy: this.usersService.getActiveUser().userId,
        ...(this.archiveMode && {
          archiveId: this.archivesService.getArchiveIdForEntity(),
        }),
      };

      this.api
        .CreateCisterna(createCisternaInput)
        .then((cisterna: CreateCisternaMutation) => {
          this.feedbacksService.showFeedback(
            `Cisterna ${cisterna.cisternaId} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos de la cisterna.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'CISTERNA',
            this.cisternaId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.archiveMode
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear cisterna ${this.cisternaId}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si la cisterna editada viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedCisterna: Cisterna = this.selectedCisterna!;
    if (
      selectedCisterna.status.endsWith(appConstants.entity.codes.rejected) ||
      selectedCisterna.status.endsWith(appConstants.entity.codes.blocked) ||
      selectedCisterna.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (
        selectedCisterna.status.endsWith(appConstants.entity.codes.rejected)
      ) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedCisterna.status.endsWith(appConstants.entity.codes.available)
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedCisterna.business,
        notificationId:
          'CISTERNA#' + selectedCisterna.cisternaId + '#' + uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'CISTERNA',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        cisternaId: selectedCisterna.cisternaId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          let message: string =
            'Se ha enviado una notificación por la actualización ';
          message += `de la cisterna ${this.cisternaIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Navega al detalle de la cisterna.
   */
  onCancel(): void {
    // Si el usuario cancela, se termina el flujo de archivado.
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
    // Regresamos al detalle de la cisterna.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.cisternaForm);
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
  }
}
