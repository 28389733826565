import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';
import { DashboardPermissionsComponent } from './dashboard-permissions/dashboard-permissions.component';
import { AdministratorPermissionsComponent } from './administrator-permissions.component';
const routes: Routes = [
  {
    path: ':business/documentacion',
    component: AdministratorPermissionsComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    children: [{ path: '', component: DashboardPermissionsComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministratorPermissionsRoutingModule {}
