import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/pages/documents/documents.service';
import { UsersService } from 'src/app/pages/users/users.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent {
  form: FormGroup = new FormGroup({
    archive: new FormControl(null, Validators.required),
  });
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  disableButton: boolean = true;

  constructor(
    private documentsService: DocumentsService,
    private usersService: UsersService
  ) {}
  submit() {
    console.log(this.form.value);
    this.documentsService
      .uploadCommercialAgreementsExcel(
        this.form.value.archive,
        Date.now().toString(),
        this.usersService.business.value.toUpperCase(),
        this.usersService.getActiveUser().email
      )
      .then(() => {
        this.c('load complete');
      })
      .catch((error) => {
        console.log('ERROR: ', error);
      });
  }

  onChangeImageFile(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      this.form.get('archive')?.setValue(file);
      // Creamos un lector de archivos local.
      const fileReader: FileReader = new FileReader();
      fileReader.onload = (): void => {};
      fileReader.onloadend = (): void => {
        //activa el botton una vez que el archivo se cargo completamente.
        this.disableButton = false;
      };
    }
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
