<form [formGroup]="formSelections" (ngSubmit)="getDocumentSpecs()">
  <div class="form-container">
    <div class="search-container">
      <div class="form-group">
        <label for="">Centro</label>
        <select formControlName="selectedCenter" class="form-control">
          <option value="">Seleccione</option>
          <option *ngFor="let center of centers" [value]="center.valueId">
            {{ center.valueToDisplay }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="">Entidad</label>
        <select formControlName="selectedEntity" class="form-control">
          <option value="">Seleccione</option>
          <option *ngFor="let entity of entities" [value]="entity.value">
            {{ entity.valueToDisplay }}
          </option>
        </select>
      </div>
    </div>

    <div class="container-button">
      <button
        class="btn btn-outline-primary htr-button"
        type="button"
        [disabled]="loading || documentSpecsControls.length == 0"
        (click)="saveAll()"
      >
        Actualizar
      </button>
    </div>
  </div>
</form>
<div class="text-center my-5" *ngIf="loading">
  <app-spinner [message]="'Cargando lista de Documentos'"></app-spinner>
</div>
<div *ngIf="!loading && documentSpecsControls.length > 0">
  <form [formGroup]="documentSpecsForm" action="">
    <div>
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption>
          Lista de Documentos
        </caption>
        <thead>
          <tr>
            <th>Documento</th>
            <th class="text-center">Obligatorio</th>
            <th class="text-center">Bloqueante</th>
            <th>Días de prórroga</th>
            <th>Última Actualización</th>
            <th>Actualizado por</th>
          </tr>
        </thead>
        <tbody formArrayName="documentSpecs">
          <tr
            *ngFor="
              let specControl of documentSpecsControls.controls;
              let i = index
            "
            [formGroupName]="i"
          >
            <td>
              {{
                transformString(specControl.value.documentSpecsId)
                  | master
                    : masterReference
                    : "[Error]: No existe referencia en Maestro"
              }}
            </td>
            <td class="toggle-switch text-center">
              <label class="switch">
                <input type="checkbox" formControlName="isMandatory" />
                <span class="slider round"></span>
              </label>
            </td>
            <td class="toggle-switch text-center">
              <label class="switch">
                <input type="checkbox" formControlName="canBlock" />
                <span class="slider round"></span>
              </label>
            </td>
            <td>
              <div class="form-group">
                <input min="0" type="number" formControlName="extensionDays" />
              </div>
            </td>
            <td>
              <label for="">{{
                specControl.value.updatedAt | dateFormat | dateWithoutSeconds
              }}</label>
            </td>
            <td>
              <label for=""> {{ specControl.value.updatedBy }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>

<!-- Modals -->
<ng-template #name></ng-template>

<ng-template #confimationCentersModal let-c="close" let-d="dismiss">
  <app-centers-selecction-modal
    [centers]="centersWithoutSelected"
    [form]="form"
    [selectedCenter]="selectedCenterName"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-centers-selecction-modal>
</ng-template>
