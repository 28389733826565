import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardPermissionsComponent } from './dashboard-permissions/dashboard-permissions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdministratorPermissionsRoutingModule } from './administrator-permissions-routing-module';
import { RouterModule } from '@angular/router';
import { AdministratorPermissionsComponent } from './administrator-permissions.component';
import { MasterModule } from '../master/master.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CentersSelecctionModalComponent } from './modals/centers-selecction-modal/centers-selecction-modal.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    DashboardPermissionsComponent,
    AdministratorPermissionsComponent,
    CentersSelecctionModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AdministratorPermissionsRoutingModule,
    RouterModule,
    MasterModule,
    SharedModule,
    DataTablesModule,
  ],
})
export class AdministratorPermissionsModule {}
