import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommercialAgreement } from 'src/app/app-sync.service';
import { appConstants } from 'src/app/shared/constants/constants';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { CommercialAgreementsService } from './commercial-agreements.service';
import { MasterService } from '../master/master.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { UsersService } from '../users/users.service';

@Component({
  selector: 'app-commercial-agreements',
  templateUrl: './commercial-agreements.component.html',
  styleUrls: ['./commercial-agreements.component.css'],
})
export class CommercialAgreementsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  commercialAgreements: CommercialAgreement[] = [];
  selectedAgreements: CommercialAgreement[] = [];
  commercialFormGroup: FormGroup;

  master: any = {};
  masterDisplayMap: any = {};
  isGettingAgreements: boolean = false;
  @ViewChild('uploadModal', { static: false }) uploadModal:
    | TemplateRef<any>
    | undefined;
  @ViewChild('updateListModal', { static: false }) updateListModal:
    | TemplateRef<any>
    | undefined;
  action: 'reject' | 'aproveN1' | 'aproveN2' | 'cancel' = 'aproveN1';
  constructor(
    private modalsService: ModalsService,
    private commercialAgreementsService: CommercialAgreementsService,
    private masterService: MasterService,
    private fb: FormBuilder,
    private usersService: UsersService
  ) {
    this.dtOptions = appConstants.datatables.options;
    this.commercialFormGroup = this.fb.group({
      commercialAgreementsForm: this.fb.array([]),
    });
    //this.commercialAgreementsForm = this.fb.array([])
  }

  ngOnInit(): void {
    this.isGettingAgreements = true;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.getCommercialAgreements();
  }

  async getCommercialAgreements() {
    this.commercialAgreements =
      await this.commercialAgreementsService.getCommercialAgreementList();
    this.commercialAgreements.forEach((element) => {
      this.acControls.push(this.fb.group({ selected: false, AC: element }));
    });
    if (this.commercialAgreements) {
      this.isGettingAgreements = false;
    }
    console.log(this.commercialAgreements);
  }
  get acControls(): FormArray {
    return this.commercialFormGroup.get(
      'commercialAgreementsForm'
    ) as FormArray;
  }

  async showUploadModal() {
    await this.modalsService.showModal(
      <TemplateRef<any>>this.uploadModal,
      false,
      'lg'
    );
  }

  async showUpdateListModal(action: 'reject' | 'aprove' | 'cancel') {
    switch (action) {
      case 'reject':
        this.action = 'reject';
        break;
      case 'cancel':
        this.action = 'cancel';
        break;

      case 'aprove':
        if (this.validateSelectedAgreementsStatus('Pendiente N1')) {
          this.action = 'aproveN1';
        } else if (this.validateSelectedAgreementsStatus('Pendiente N2')) {
          this.action = 'aproveN2';
        }
        break;
    }
    this.selectedAgreements = this.getSelectedAgreements();
    await this.modalsService.showModal(<TemplateRef<any>>this.updateListModal);
  }
  getSelectedAgreements(): CommercialAgreement[] {
    return this.acControls.controls
      .filter((control) => control.get('selected')?.value) //Filtra solo los seleccionados
      .map((control) => control.get('AC')?.value as CommercialAgreement); // Extrae el objeto AC
  }

  validateSelectedAgreementsStatus(expectedStatus: string): boolean {
    const selectedControls = this.acControls.controls.filter(
      (control) => control.get('selected')?.value
    );

    return (
      selectedControls.length > 0 &&
      selectedControls.every(
        (control) => control.get('AC')?.value?.status === expectedStatus
      )
    );
  }

  isButtonActive(button: string): boolean {
    let isActive = false;
    if (
      button === 'aprove' &&
      this.validateSelectedAgreementsStatus('Pendiente N1') &&
      this.usersService.isAcN1
    ) {
      isActive = true;
    }
    if (
      button === 'aprove' &&
      this.validateSelectedAgreementsStatus('Pendiente N2') &&
      this.usersService.isAcN2
    ) {
      isActive = true;
    }

    if (
      button === 'reject' &&
      this.validateSelectedAgreementsStatus('Pendiente N1') &&
      this.usersService.isAcN1
    ) {
      isActive = true;
    }
    if (
      button === 'reject' &&
      this.validateSelectedAgreementsStatus('Pendiente N2') &&
      this.usersService.isAcN2
    ) {
      isActive = true;
    }
    return isActive;
  }
  isActiveUpload(): boolean {
    let isActive = false;
    if (this.usersService.isAcN1 || this.usersService.isAcCreator) {
      isActive = true;
    }
    return isActive;
  }
}
