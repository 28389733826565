<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ title }}
  </h4>
  <button
    id="details-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong>
      {{ message }}
    </strong>
  </p>

  <div class="scrollable-list">
    <ul>
      <li *ngFor="let commercialAgreement of List">
        {{ commercialAgreement.sapId | sapId }} -
        {{ commercialAgreement.entityId }} con fecha
        {{ actualDate | date : "dd/MM/yyyy" }}
      </li>
    </ul>
  </div>

  <div>
    <p>{{ question }}<br /></p>
  </div>

  <div>
    <form [formGroup]="commentForm" *ngIf="hasComment">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm">
            <label for="comment" class="form-label"
              >Comentario
              <span class="text-danger fs-4" *ngIf="hasComment">*</span></label
            >
            <div class="form-group">
              <input
                type="text"
                id="comment"
                formControlName="comment"
                class="form-control immutable-field"
                placeholder="máximo 300 caracteres"
                appInputTrim
              />
              <div
                *ngIf="
                  showHelper(notificationControl.get('comment')) && hasComment
                "
              >
                <small class="form-text text-muted">
                  {{ helperMessages(notificationControl.get("comment")) }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button
    id="cisterna-details-update-attr-modal-cancel"
    type="button"
    class="btn btn-danger"
    (click)="d('Cancel')"
  >
    Cancelar
  </button>
  <button
    id="cisterna-details-update-attr-modal-accept"
    type="button"
    [disabled]="hasComment && commentForm.invalid"
    class="btn btn-primary"
    (click)="c('Accept')"
  >
    Aceptar
  </button>
</div>
