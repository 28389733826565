import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { appConstants } from '../../../shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateSemirremolqueInput,
  CreateSemirremolqueMutation,
  CreateNotificationInput,
  Document,
  Semirremolque,
  UpdateSemirremolqueInput,
} from '../../../app-sync.service';
import { DocumentsService } from '../../documents/documents.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { MasterService } from '../../master/master.service';
import { SettingsService } from '../../settings/settings.service';
import { UsersService } from '../../users/users.service';
import { ValidatorsService } from '../../../shared/services/validators.service';
import { SemirremolquesService } from '../semirremolques.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-semirremolque-edit',
  templateUrl: './semirremolque-edit.component.html',
  styleUrls: ['./semirremolque-edit.component.css'],
})
export class SemirremolqueEditComponent implements OnInit, OnDestroy {
  entityName: string = 'Semirremolque';
  selectedSemirremolque: Semirremolque | undefined = undefined;
  semirremolqueIdentification: string = '';
  equipType: string = '';
  equipTypeToDisplay: string = '';
  currentYear: number = new Date().getFullYear();
  editMode: boolean = true;
  archiveMode: boolean = false;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingSemirremolque: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  selectedCenter: string = '';
  semirremolqueForm: FormGroup;
  semirremolqueId: string = '';
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';

  get documentsControl() {
    return (<FormArray>this.semirremolqueForm.get('documents')).controls;
  }

  get disableSubmit() {
    return (
      this.semirremolqueForm.invalid ||
      this.semirremolqueForm.pristine ||
      this.semirremolqueForm.untouched
    );
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private semirremolquesService: SemirremolquesService,
    private formsService: FormsService,
    private editionsService: EditionsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.semirremolqueForm = new FormGroup({
      carrier: new FormControl(null),
      licensePlate: new FormControl(null),
      brand: new FormControl(null),
      model: new FormControl(null),
      year: new FormControl(null),
      color: new FormControl(null),
      type: new FormControl(null),
      capacity: new FormControl(null),
      subBusiness: new FormControl(null),
      center: new FormControl(null),
      gpsImei: new FormControl(null),
      cameraImei: new FormControl(null),
      forMining: new FormControl(null),
      hasRanchAccreditation: new FormControl(null),
      isSpot: new FormControl(null),
      rampType: new FormControl(null),
      MOPCode: new FormControl(null),
      maximumPalletCapacity: new FormControl(null),
      height: new FormControl(null),
      width: new FormControl(null),
      length: new FormControl(null),
      documents: this.formsService.documentsFormArrayInitialization(),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit() {
    this.isGettingSemirremolque = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.archiveMode = this.archivesService.getArchiving();
    this.selectedSemirremolque =
      this.semirremolquesService.getSelectedSemirremolque();
    this.semirremolqueIdentification = this.commonsService.getIdentification(
      this.selectedSemirremolque.sapId!,
      this.selectedSemirremolque.licensePlate
    );

    this.equipType = this.master['SEMIRREMOLQUES#TYPES'][0].valueId;
    this.equipTypeToDisplay =
      this.masterDisplayMap['SEMIRREMOLQUES#TYPES'][this.equipType];

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de semirremolque
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.semirremolqueId = params['id'];

      this.initSemirremolqueForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'SEMIRREMOLQUE') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('SEMIRREMOLQUE');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#SEMIRREMOLQUE#${this.semirremolqueId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingSemirremolque = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un semirremolque.
   * @private
   */
  private initSemirremolqueForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    const editingOrArchiving: boolean = this.editMode || this.archiveMode;
    let initialSemirremolque: Semirremolque = {
      ...appConstants.semirremolque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let semirremolqueDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialSemirremolque.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';
    // El tipo de equipo es un valor fijo.
    initialSemirremolque.type = this.equipType;

    if (editingOrArchiving) {
      // En modo de Edición: cargamos los valores de los campos
      // según el semirremolque escogido.
      // En modo Archivar: cargamos los valores de los campos según
      // el semirremolque archivado (que sigue siendo el seleccionado).
      initialSemirremolque = this.selectedSemirremolque!;
      const selectedSemirremolqueDocuments: Document[] =
        this.documentsService.getDocuments();

      let documentMasterValueIdsList: string[] = [];
      semirremolqueDocuments.removeAt(0);
      for (let document of selectedSemirremolqueDocuments) {
        semirremolqueDocuments.push(
          this.formsService.createDocumentControlFromDocument(
            document,
            !this.archiveMode
          )
        );
        documentMasterValueIdsList.push(document.masterValueId);
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      semirremolqueDocuments = this.setAdditionalDocumentsFormGroup(
        initialSemirremolque.center,
        semirremolqueDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      semirremolqueDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.semirremolqueForm = new FormGroup({
      carrier: new FormControl(
        {
          value: initialSemirremolque.carrier.carrierId,
          disabled: this.editMode,
        },
        Validators.required
      ),
      licensePlate: new FormControl(
        {
          value: initialSemirremolque.licensePlate,
          disabled: this.disableLicensePlate(),
        },
        [
          Validators.required,
          this.validatorsService.licensePlatePatternValidator(this.business),
        ],
        [this.validatorsService.licensePlateInUseValidator()]
      ),
      brand: new FormControl(
        { value: initialSemirremolque.brand, disabled: this.editMode },
        Validators.required
      ),
      model: new FormControl(
        { value: initialSemirremolque.model, disabled: this.editMode },
        Validators.required
      ),
      year: new FormControl(
        {
          value:
            initialSemirremolque.year === 0 ? null : initialSemirremolque.year,
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern(appConstants.regex.year)]
      ),
      color: new FormControl(
        { value: initialSemirremolque.color, disabled: this.editMode },
        Validators.required
      ),
      type: new FormControl(
        { value: initialSemirremolque.type, disabled: this.editMode },
        Validators.required
      ),
      capacity: new FormControl(
        { value: initialSemirremolque.capacity, disabled: this.editMode },
        Validators.required
      ),
      center: new FormControl(
        { value: initialSemirremolque.center, disabled: this.editMode },
        Validators.required
      ),
      subBusiness: new FormControl(
        { value: initialSemirremolque.subBusiness, disabled: this.editMode },
        Validators.required
      ),
      gpsImei: new FormControl({
        value: initialSemirremolque.gpsImei,
        disabled: this.editMode,
      }),
      cameraImei: new FormControl({
        value: initialSemirremolque.cameraImei,
        disabled: this.editMode,
      }),
      forMining: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialSemirremolque.forMining
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      hasRanchAccreditation: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialSemirremolque.hasRanchAccreditation
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      isSpot: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialSemirremolque.isSpot
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      rampType: new FormControl(
        { value: initialSemirremolque.rampType, disabled: this.editMode },
        Validators.required
      ),
      MOPCode: new FormControl(
        { value: initialSemirremolque.MOPCode, disabled: this.editMode },
        Validators.required
      ),
      maximumPalletCapacity: new FormControl(
        {
          value:
            initialSemirremolque.maximumPalletCapacity === 0
              ? null
              : initialSemirremolque.maximumPalletCapacity,
          disabled: this.editMode,
        },
        Validators.required
      ),
      height: new FormControl(
        {
          value: this.checkNumberValue(initialSemirremolque.height),
          disabled: this.editMode,
        },
        Validators.required
      ),
      width: new FormControl(
        {
          value: this.checkNumberValue(initialSemirremolque.width),
          disabled: this.editMode,
        },
        Validators.required
      ),
      length: new FormControl(
        {
          value: this.checkNumberValue(initialSemirremolque.length),
          disabled: this.editMode,
        },
        Validators.required
      ),
      documents: semirremolqueDocuments,
    });
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece el semirremolque.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let semirremolqueDocuments =
      this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['SEMIRREMOLQUES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    semirremolqueDocuments.removeAt(0);
    for (let document of documentTypes) {
      const validators: FormValidators = this.getValidators(
        document.valueId,
        center
      );
      semirremolqueDocuments.push(
        this.formsService.createDocumentControlFromMasterWithValidators(
          document,
          validators
        )
      );
    }

    return semirremolqueDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece el semirremolque.
   * @param {FormArray} semirremolqueDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el semirremolqueDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    semirremolqueDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['SEMIRREMOLQUES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    for (let document of documentTypes) {
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (documentMasterValueIdsList.indexOf(document.valueId) === -1) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        semirremolqueDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return semirremolqueDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string }center Centro al que pertenece el semirremolque.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onSemirremolqueCenterChange(): void {
    if (this.selectedCenter === this.semirremolqueForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.semirremolqueForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const semirremolqueDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.semirremolqueForm.removeControl('documents');
      this.semirremolqueForm.setControl('documents', semirremolqueDocuments);
    }
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    console.log(' --- onChangeDocumentFile ---');
    console.log(target);
    console.log(target.files![0]);
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      (<FormArray>this.semirremolqueForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company: string = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.semirremolqueForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /**
   * Determina si se debe deshabilitar el input
   * para la patente.
   */
  private disableLicensePlate(): boolean {
    return (
      this.editMode ||
      (this.archiveMode && !this.archivesService.hasToChangeID())
    );
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un semirremolque.
   */
  async onSubmit(): Promise<void> {
    console.log(' onSubmit @ semirremolque-edit.component ');
    console.log(this.semirremolqueForm.value);
    const newSemirremolque = this.semirremolqueForm.value;
    const documentList: DocumentFromForms[] =
      this.semirremolqueForm.value.documents.slice();

    if (this.disableLicensePlate()) {
      // Si la patente fue deshabilitada se asigna acá.
      newSemirremolque['licensePlate'] =
        this.selectedSemirremolque!.licensePlate;
    }

    console.log('newSemirremolque', newSemirremolque);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza el semirremolque.
      let semirremolqueEdited: boolean = false;
      documentList.forEach((document: any) => {
        semirremolqueEdited =
          semirremolqueEdited || Boolean(document.documentSourceFile);
        console.log(semirremolqueEdited);
      });
      if (!semirremolqueEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar el semirremolque',
          'danger'
        );
        return;
      }

      // Nota: En el modo edición los ID fueron fijados al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando semirremolque: ' + this.semirremolqueId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo edición, solo pueden editarse los documentos
      // Sin embargo, el semirremolque vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia el estatus del semirremolque
      const updateSemirremolqueInput: UpdateSemirremolqueInput = {
        semirremolqueId: this.semirremolqueId,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .UpdateSemirremolque(updateSemirremolqueInput)
        .then(() => {
          // 2. Se cargan los documentos del semirremolque, si aplica.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'SEMIRREMOLQUE',
            this.semirremolqueId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );

          // 3. Se actualiza el estado del acoplamiento, si aplica.
          const selectedSemirremolque: Semirremolque =
            this.selectedSemirremolque!;
          if (selectedSemirremolque.semirremolqueCoupleCoupleId) {
            this.api.UpdateCouple({
              coupleId: selectedSemirremolque.semirremolqueCoupleCoupleId,
              status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
            });
          }
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar semirremolque ${this.semirremolqueId}`
          );
        });
    } else {
      // Si es un flujo de archivado,
      // Se elimina primero la entidad anterior
      // para evitar conflictos si es la misma patente.
      if (this.archiveMode) {
        // 1. Eliminar entidad anterior
        // (en este punto sigue como la que está seleccionada)
        await this.semirremolquesService.deleteSelectedSemirremolque(false);

        // 2. Liberamos el modo de archivado
        this.archivesService.setArchiving(false);
      }

      // En el modo crear los ID se toman desde el formulario.
      this.semirremolqueId = newSemirremolque.licensePlate.toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando semirremolque: ${this.semirremolqueId}`,
        'info'
      );

      // En modo creación o archivo:
      // 1. Se crea un nuevo semirremolque.
      const createSemirremolqueInput: CreateSemirremolqueInput = {
        semirremolqueId: this.semirremolqueId,
        brand: newSemirremolque.brand,
        licensePlate: this.semirremolqueId, // La licencia corresponde con el ID
        color: newSemirremolque.color,
        model: newSemirremolque.model.toUpperCase(),
        year: newSemirremolque.year,
        type: newSemirremolque.type,
        capacity: newSemirremolque.capacity,
        business: this.business,
        subBusiness: newSemirremolque.subBusiness,
        center: newSemirremolque.center,
        originalCenter: newSemirremolque.center,
        blockingMotives: [],
        gpsImei: newSemirremolque.gpsImei,
        cameraImei: newSemirremolque.cameraImei,
        forMining: newSemirremolque.forMining === 'true',
        hasRanchAccreditation:
          newSemirremolque.hasRanchAccreditation === 'true',
        isSpot: newSemirremolque.isSpot === 'true',
        rampType: newSemirremolque.rampType,
        MOPCode: newSemirremolque.MOPCode,
        maximumPalletCapacity: newSemirremolque.maximumPalletCapacity,
        height: newSemirremolque.height,
        width: newSemirremolque.width,
        length: newSemirremolque.length,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`, // Un nuevo semirremolque debe ser aprobado
        company: newSemirremolque.carrier,
        carrierSemirremolquesCarrierId: newSemirremolque.carrier,
        updatedBy: this.usersService.getActiveUser().userId,
        ...(this.archiveMode && {
          archiveId: this.archivesService.getArchiveIdForEntity(),
        }),
      };
      console.log('createSemirremolqueInput', createSemirremolqueInput);

      this.api
        .CreateSemirremolque(createSemirremolqueInput)
        .then((semirremolque: CreateSemirremolqueMutation) => {
          this.feedbacksService.showFeedback(
            `Semirremolque ${semirremolque.semirremolqueId} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos del semirremolque.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'SEMIRREMOLQUE',
            this.semirremolqueId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.archiveMode
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear semirremolque ${this.semirremolqueId}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si el semirremolque editado viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedSemirremolque: Semirremolque = this.selectedSemirremolque!;
    if (
      selectedSemirremolque.status.endsWith(
        appConstants.entity.codes.rejected
      ) ||
      selectedSemirremolque.status.endsWith(
        appConstants.entity.codes.blocked
      ) ||
      selectedSemirremolque.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (
        selectedSemirremolque.status.endsWith(
          appConstants.entity.codes.rejected
        )
      ) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedSemirremolque.status.endsWith(
          appConstants.entity.codes.available
        )
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedSemirremolque.business,
        notificationId:
          'SEMIRREMOLQUE#' +
          selectedSemirremolque.semirremolqueId +
          '#' +
          uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'SEMIRREMOLQUE',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        semirremolqueId: selectedSemirremolque.semirremolqueId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          let message: string =
            'Se ha enviado una notificación por la actualización ';
          message += `del semirremolque ${this.semirremolqueIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Navega al detalle del semirremolque.
   */
  onCancel(): void {
    // Si el usuario cancela, se termina el flujo de archivado.
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
    // Regresamos al detalle del semirremolque.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.semirremolqueForm);
  }

  /**
   * Verifica el valor de un atributo para retornar nulo o el valor del atributo.
   * @param {number | null | undefined} attributeValue Valor del atributo.
   * @return {number | null | undefined} El valor del atributo, si es distinto de cero, o nulo, en caso contrario.
   * @private
   */
  private checkNumberValue(
    attributeValue: number | null | undefined
  ): number | null | undefined {
    return attributeValue === 0 ? null : attributeValue;
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
  }
}
