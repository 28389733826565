<nav class="navbar navbar-expand-sm">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" class="navbar-brand">
        <img
          id="header-logo"
          ngSrc="../../assets/images/logo/ROZ-logo.svg"
          alt="Logo"
          width="200"
          height="64"
          class="d-inline-block align-text-top"
          priority
        />
      </a>
    </div>
    <div
      class="collapse navbar-collapse"
      style="height: 100px; border-radius: 0 0 0 30px; font-size: 12px"
      id="navbarNavLeft"
    >
      <a *ngIf="!activeUserInHome" class="title-new">{{
        business.toUpperCase() | master : "BUSINESSES" : business.toUpperCase()
      }}</a>
      <!-- Start Dropdowns -->
      <div *ngIf="!activeUserInHome" class="row dropdown-container">
        <div class="col">
          <div ngbDropdown class="d-inline-block t-first-button">
            <button
              type="button"
              class="btn btn-outline-primary dropdownButton"
              id="dropdownNav"
              ngbDropdownToggle
            >
              Navegar
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownNav"
              class="dropdownNav"
            >
              <a
                id="header-a-vehicles"
                [ngClass]="getClass('vehicles')"
                *ngIf="showButton('vehicles')"
                (click)="onSelected('vehicles')"
                ngbDropdownItem
                >Vehículos</a
              >
              <a
                id="header-a-envasados"
                [ngClass]="getClass('envasados')"
                *ngIf="showButton('envasados')"
                (click)="onSelected('envasados')"
                ngbDropdownItem
              >
                Envasados Rígidos</a
              >
              <a
                id="header-a-cisternas"
                [ngClass]="getClass('cisternas')"
                *ngIf="showButton('cisternas')"
                (click)="onSelected('cisternas')"
                ngbDropdownItem
              >
                Cisternas</a
              >
              <a
                id="header-a-tractos"
                [ngClass]="getClass('tractos')"
                *ngIf="showButton('tractos')"
                (click)="onSelected('tractos')"
                ngbDropdownItem
              >
                Tractos</a
              >
              <a
                id="header-a-tanques"
                [ngClass]="getClass('tanques')"
                *ngIf="showButton('tanques')"
                (click)="onSelected('tanques')"
                ngbDropdownItem
              >
                Tanques</a
              >
              <a
                id="header-a-semirremolques"
                [ngClass]="getClass('semirremolques')"
                *ngIf="showButton('semirremolques')"
                (click)="onSelected('semirremolques')"
                ngbDropdownItem
              >
                Semirremolques</a
              >
              <hr *ngIf="showButton('couplings')" class="dropdown-divider" />
              <a
                id="header-a-couplings"
                [ngClass]="getClass('couplings')"
                *ngIf="showButton('couplings')"
                (click)="onSelected('couplings')"
                ngbDropdownItem
              >
                Acoplamientos</a
              >
              <a
                id="header-a-rtc-history"
                [ngClass]="getClass('rtc-history')"
                *ngIf="showButton('rtc-history')"
                (click)="onSelected('rtc-history')"
                ngbDropdownItem
              >
                Historial RTC</a
              >
              <a
                id="header-a-commercial-agreements"
                [ngClass]="getClass('commercial-agreements')"
                *ngIf="showButton('commercial-agreements')"
                (click)="onSelected('commercial-agreements')"
                ngbDropdownItem
              >
                Acuerdos Comerciales</a
              >
              <hr *ngIf="showButton('drivers')" class="dropdown-divider" />
              <a
                id="header-a-drivers"
                [ngClass]="getClass('drivers')"
                *ngIf="showButton('drivers')"
                (click)="onSelected('drivers')"
                ngbDropdownItem
              >
                Conductores</a
              >
              <hr *ngIf="showButton('users')" class="dropdown-divider" />
              <a
                id="header-a-users"
                [ngClass]="getClass('users')"
                *ngIf="showButton('users')"
                (click)="onSelected('users')"
                ngbDropdownItem
              >
                Usuarios</a
              >
              <a
                id="header-a-rtc-evaluate"
                [ngClass]="getClass('rtc-evaluate')"
                *ngIf="showButton('rtc')"
                (click)="onSelected('rtc-evaluate')"
                ngbDropdownItem
              >
                Evaluar</a
              >
            </div>
          </div>
        </div>
        <div *ngIf="showButton('excel')" class="col">
          <div ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-outline-primary dropdownButton"
              id="dropdownActions"
              ngbDropdownToggle
            >
              Acciones
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownActions">
              <a
                id="header-a-download-excel"
                *ngIf="showButton('excel')"
                (click)="onDownloadExcel()"
                ngbDropdownItem
              >
                Descargar Datos</a
              >
            </div>
          </div>
        </div>
        <div *ngIf="showButton('settings')" class="col">
          <div ngbDropdown class="d-inline-block">
            <button
              type="button"
              class="btn btn-outline-primary dropdownButton"
              id="dropdownSettings"
              ngbDropdownToggle
            >
              <span class="material-symbols-outlined"> settings </span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSettings">
              <a
                id="header-a-documentacion"
                *ngIf="showButton('documentacion')"
                (click)="onSelected('documentacion')"
                ngbDropdownItem
              >
                Documentación</a
              >
            </div>
          </div>
        </div>
      </div>
      <!--End Dropdowns  -->
    </div>
    <div
      class="collapse navbar-collapse justify-content-end"
      style="height: 100px; border-radius: 0 0 30px 0; padding-top: 30px"
      id="navbarNavRight"
    >
      <div class="dropdown" style="margin-right: 25px; padding-bottom: 15px">
        <a
          class="navbar-text text-light px-3 btn-lg dropdown-toggle"
          *ngIf="showButton('user-name')"
          id="header-user-name"
          (click)="toggleDropdown()"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="fs-6" style="color: #6688a3">{{ activeUserName }} </span>
          <span
            class="material-symbols-outlined align-bottom"
            style="color: #6688a3"
          >
            account_circle
          </span>
        </a>
        <div
          class="dropdown-menu"
          [class.show]="isDropdownOpen"
          (click)="selectOption($event)"
        >
          <a
            class="dropdown-item no-hover"
            style="color: black; font-weight: 500; pointer-events: none"
            >{{ roleDescription }}</a
          >
          <div class="separator"></div>
          <a class="dropdown-item" (click)="toggleSidebar()" *ngIf="isAdmin"
            >Notificaciones</a
          >
          <a
            class="dropdown-item"
            (click)="onLogOut()"
            *ngIf="showButton('logout')"
            >Cerrar Sesión <i class="fas fa-sign-out-alt"></i
          ></a>
        </div>
      </div>

      <div class="sidebar sidebar-right" [class.active]="isSidebarOpen">
        <button
          id="sidebar-close"
          type="button"
          class="btn-close m-2"
          aria-label="Close"
          (click)="toggleSidebar()"
        ></button>
        <p
          style="
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
          "
        >
          Notificaciones
        </p>
        <p
          style="
            text-align: center;
            font-size: 15px;
            max-width: 80%;
            margin: 0 auto 29px;
            color: black;
          "
        >
          Encienda un concepto para recibir notificaciones asociadas o apáguelo
          para dejar de recibirlas.
        </p>
        <div
          class="col-sm text-center my-5"
          *ngIf="isGettingMailPreferences || isUpdatingPreferences"
        >
          <app-spinner
            [message]="
              (isGettingMailPreferences ? 'Cargando' : 'Actualizando') +
              ' preferencias...'
            "
          ></app-spinner>
        </div>
        <div
          class="mx-1"
          *ngIf="!isGettingMailPreferences && !isUpdatingPreferences"
        >
          <table class="mail-preferences">
            <caption>
              Preferencias de Notificación
            </caption>
            <tbody>
              <tr
                *ngFor="let preference of mailPreferences"
                class="preference-item"
              >
                <th scope="row" class="preference-info">
                  {{ preference.name }}
                </th>
                <td class="toggle-switch">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="preference.receive"
                      (change)="toggleReceive(preference)"
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="buttons-container mt-4">
            <button
              type="button"
              class="btn btn-outline-danger mx-5"
              (click)="toggleSidebar()"
            >
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-outline-primary mx-5"
              (click)="actualizarPreferenciasDeCorreo()"
              [disabled]="actualizandoPreferencias"
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Modals -->
<ng-template #headerModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
    <button
      id="header-excel-modal-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      <strong>{{ modalQuestion }}</strong>
    </p>
    <p>{{ modalMessage }}</p>
    <form [formGroup]="excelForm">
      <div class="container-fluid">
        <div class="row">
          <div id="header-models-all" class="col-sm-4">
            <label class="form-label">
              <input
                type="checkbox"
                id="selectAll"
                formControlName="selectAll"
                [value]="selectAll"
                (change)="selectAllModels()"
              />
              Seleccionar Todo
            </label>
          </div>
        </div>
        <div class="row border-top py-2" formArrayName="models">
          <div
            id="header-models"
            class="col-sm-4"
            *ngFor="let centerControl of modelsControl; let i = index"
            [formGroupName]="i"
          >
            <label class="form-label">
              <input
                type="checkbox"
                id="model{{ i }}"
                formControlName="wanted"
                [value]="centerControl.get('wanted')!.value"
                (change)="resetSelectAll()"
              />
              {{ centerControl.get("modelName")!.value | modelName }}
            </label>
          </div>
        </div>
        <div *ngIf="showHelper(excelForm)">
          <small class="form-text text-muted">
            {{ helperMessages(excelForm) }}
          </small>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      id="header-excel-modal-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="header-excel-modal-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
      [disabled]="!excelForm.valid"
    >
      Aceptar
    </button>
  </div>
</ng-template>
