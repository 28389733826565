import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SettingsService } from '../../settings/settings.service';
import {
  DocumentSpecs,
  UpdateDocumentSpecsInput,
} from 'src/app/app-sync.service';
import { MasterService } from '../../master/master.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { appConstants } from 'src/app/shared/constants/constants';
import { UsersService } from '../../users/users.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';
import { MasterPipe } from '../../master/master.pipe';

@Component({
  selector: 'app-dashboard-permissions',
  templateUrl: './dashboard-permissions.component.html',
  styleUrls: ['./dashboard-permissions.component.css'],
  providers: [MasterPipe],
})
export class DashboardPermissionsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild('confimationCentersModal', { static: false })
  confimationCentersModal: TemplateRef<any> | undefined;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecs[] = [];
  centers: Array<any> = [''];
  entities: Array<any> = [];
  masterReference: string = '';
  ENTITIESBYBUSSINES = {
    nocore: [
      { value: 'VEHICLE', valueToDisplay: 'Vehiculos' },
      { value: 'DRIVER', valueToDisplay: 'Conductores' },
    ],
    lubricants: [
      { value: 'TANQUE', valueToDisplay: 'Tanques' },
      { value: 'TRACTO', valueToDisplay: 'Tractos' },
      { value: 'CISTERNA', valueToDisplay: 'Cisternas' },
      { value: 'ENVASADO', valueToDisplay: 'Envasados' },
      { value: 'SEMIRREMOLQUE', valueToDisplay: 'Semirremolques' },
      { value: 'DRIVER', valueToDisplay: 'Conductores' },
    ],
    foreign: [
      { value: 'TANQUE', valueToDisplay: 'Tanques' },
      { value: 'TRACTO', valueToDisplay: 'Tractos' },
      { value: 'DRIVER', valueToDisplay: 'Conductores' },
    ],
  };
  formSelections: FormGroup = new FormGroup({
    selectedCenter: new FormControl('', Validators.required),
    selectedEntity: new FormControl('', Validators.required),
  });
  loading: boolean = false;
  documentSpecsForm: FormGroup;
  selectedCenter: string = '';
  form: FormGroup;
  business: string = '';

  constructor(
    private settingsService: SettingsService,
    private masterService: MasterService,
    private fb: FormBuilder,
    private modalsService: ModalsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService,
    private masterPipe: MasterPipe
  ) {
    this.form = this.fb.group({
      selectAll: [false],
      centers: this.fb.array([]),
    });
    this.documentSpecsForm = this.fb.group({
      documentSpecs: this.fb.array([]),
    });
    this.dtOptions = appConstants.datatables.options;
  }
  ngOnInit(): void {
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.centers = this.master['CENTERS'];
    this.business = this.usersService.business.value.toLowerCase();
    this.entities =
      this.ENTITIESBYBUSSINES[
        this.business as keyof typeof this.ENTITIESBYBUSSINES
      ] || [];
    console.log(this.business);
    this.formSelections.valueChanges.subscribe((values) => {
      if (this.formSelections.valid) {
        this.getDocumentSpecs();
      }
    });
  }

  async getDocumentSpecs() {
    if (this.formSelections.invalid) {
      return;
    }
    this.loading = true;
    this.selectedCenter = this.formSelections.value.selectedCenter;
    this.masterReference = `${this.formSelections.value.selectedEntity}S#DOCUMENT_TYPES`;
    this.documentsSpecs =
      await this.settingsService.getDocumentsSpecsListWithCenter(
        this.formSelections.value.selectedEntity,
        this.selectedCenter
      );
    this.populateForm();
  }

  transformString(input: string): string {
    const parts = input.split('#');
    if (parts.length < 3) {
      return input;
    }
    return `${parts[2]}`;
  }

  populateForm(): void {
    const formArray = this.documentSpecsForm.get('documentSpecs') as FormArray;
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }

    this.documentsSpecs.forEach((spec) => {
      formArray.push(
        this.fb.group({
          documentSpecsId: [spec.documentSpecsId],
          isMandatory: [spec.isMandatory],
          canBlock: [spec.canBlock],
          extensionDays: [spec.extensionDays],
          updatedBy: [spec.updatedBy],
          owner: [spec.owner],
          createdAt: [spec.createdAt],
          updatedAt: [spec.updatedAt],
          businessId: [spec.businessId],
        })
      );
    });
    this.loading = false;
  }
  async saveAll() {
    this.form = this.fb.group({
      selectAll: [false],
      centers: this.fb.array([]),
    });
    const centerArray = this.form.get('centers') as FormArray;
    centerArray.clear();
    this.centers.forEach((center) => {
      const isSelected =
        center.valueId === this.formSelections.value.selectedCenter;
      centerArray.push(
        this.fb.group({
          center: [center],
          selected: [{ value: isSelected, disabled: isSelected }],
        })
      );
    });

    const modalResult: boolean = await this.modalsService.showModal(
      <TemplateRef<any>>this.confimationCentersModal,
      false,
      'lg'
    );

    if (!modalResult) return;
    const centers: Array<any> = this.getSelectedCenters();
    const documents = this.getTouchedValues();

    try {
      await Promise.all(
        centers.flatMap((center) =>
          documents.map(async (doc) => {
            const tempdocumentSpecsId = doc.documentSpecsId.replace(
              this.selectedCenter,
              center.valueId
            );
            let normalName = this.masterPipe.transform(
              this.transformString(tempdocumentSpecsId),
              this.masterReference
            );
            const putData: UpdateDocumentSpecsInput = {
              documentSpecsId: tempdocumentSpecsId,
              canBlock: doc.canBlock,
              businessId: doc.businessId,
              extensionDays: doc.extensionDays,
              isMandatory: doc.isMandatory,
              updatedBy: this.usersService.getActiveUser().userId,
            };

            this.feedbacksService.showFeedback(
              `Actualizando el documento: ${normalName}\n en el centro: ${center.valueToDisplay}`,
              'info'
            );

            try {
              await this.settingsService.updateDocumentsSpecs(putData);
              this.feedbacksService.showFeedback(
                `Documento ${normalName} actualizado correctamente \n en el centro: ${center.valueToDisplay}`,
                'success'
              );
            } catch (error) {
              this.feedbacksService.showErrorFeedbacks(
                error,
                `Error al actualizar documento: ${normalName}\n en el centro: ${center.valueToDisplay}`
              );
            }
          })
        )
      );
      this.getDocumentSpecs();
    } catch (error) {
      console.error('Error en la actualización de documentos:', error);
    }
  }

  getTouchedValues(): Array<any> {
    const documentSpecsArray = this.documentSpecsControls;

    if (!documentSpecsArray) {
      return [];
    }

    // Filtrar y mapear los controles que están 'touched'
    return documentSpecsArray.controls
      .filter((control) => control.touched) // Solo controles tocados
      .map((control) => control.value); // Obtener sus valores
  }
  getSelectedCenters(): string[] {
    // Obtener el estado de selectAll
    const selectAll = this.form.get('selectAll')?.value;

    // Obtener el FormArray centers
    const centerArray = this.form.get('centers') as FormArray;

    if (selectAll) {
      // Si selectAll está en true, devolver todos los valores de 'center'
      return centerArray.controls.map(
        (control) => control.get('center')?.value
      );
    } else {
      // Si selectAll está en false, filtrar los que tienen 'selected' en true
      return centerArray.controls
        .filter((control) => control.get('selected')?.value) // Filtrar los seleccionados
        .map((control) => control.get('center')?.value); // Obtener sus valores
    }
  }

  get selectedCenterName(): string {
    return this.centers.find(
      (x) => x.valueId === this.formSelections.value.selectedCenter
    ).valueToDisplay;
  }
  get centersWithoutSelected(): any[] {
    return this.centers.filter(
      (x) => x.valueId !== this.formSelections.value.selectedCenter
    );
  }
  get documentSpecsControls(): FormArray {
    return this.documentSpecsForm.get('documentSpecs') as FormArray;
  }
}
