import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { appConstants } from '../../shared/constants/constants';

import {
  APIService,
  DeleteVehicleInput,
  GetVehicleQuery,
  ModelSubscriptionIDInput,
  ModelSubscriptionVehicleFilterInput,
  UpdateVehicleInput,
  UpdateVehicleMutation,
  User,
  Vehicle,
  VehiclesByBusinessAndVehicleIdQuery,
  VehiclesByCenterAndVehicleIdQuery,
  VehiclesByCompanyAndVehicleIdQuery,
} from '../../app-sync.service';
import { DocumentsService } from '../documents/documents.service';
import { UsersService } from '../users/users.service';
import { filterInputs } from '../../shared/types/filter-inputs';
import { FeedbacksService } from '../../shared/feedbacks/feedbacks.service';
import { nextToken } from '../../shared/types/next-token';
import { CommonsService } from '../../shared/services/commons.service';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  selectedVehicleChanged = new Subject<Vehicle>();
  vehiclesChanged = new Subject<Vehicle[]>();
  vehicleHasTrailerChanged = new Subject<boolean>();
  vehicleHasTrailer: boolean = false;

  private readonly initialVehicle: Vehicle;
  private selectedVehicle: Vehicle;
  private selectedVehicleFilterForSubscriptions: ModelSubscriptionVehicleFilterInput =
    {};
  private vehicles: Vehicle[] = [];
  private vehiclesFilterForSubscriptions: ModelSubscriptionVehicleFilterInput =
    {};

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private usersService: UsersService,
    private feedbacksService: FeedbacksService,
    private commonsService: CommonsService
  ) {
    this.initialVehicle = {
      ...appConstants.vehicle.initialization,
      carrier: appConstants.carrier.initialization,
    };
    this.selectedVehicle = this.initialVehicle;
    this.setVehiclesFilter();
    this.setSelectedVehicleFilterForSubscriptions();
  }

  // -------------------------------------
  // Métodos para el vehículo seleccionado
  // -------------------------------------
  /**
   * Configura el vehículo seleccionado para referencia de
   * todos los componentes.
   * @param {Vehicle} vehicle Vehículo seleccionado.
   */
  setSelectedVehicle(vehicle: Vehicle): void {
    this.selectedVehicle = vehicle;
    this.setSelectedVehicleFilterForSubscriptions();
    this.selectedVehicleChanged.next({ ...this.selectedVehicle });
    console.log('selectedVehicle', this.selectedVehicle);

    // Documentos
    this.documentsService.setId('vehicleId', this.selectedVehicle.vehicleId);
    this.documentsService.setSelectedModel('VEHICLE');
    this.documentsService.setSelectedCenter(vehicle.center);
    this.documentsService.setDocumentsFilter();
    this.documentsService
      .refreshDocuments()
      .then(() => console.log('Documentos del vehículo actualizados.'));
  }

  /**
   * Actualiza los datos del vehículo seleccionado.
   * @return {Promise}
   */
  async refreshSelectedVehicle(): Promise<void> {
    const getVehicleResult: GetVehicleQuery = await this.api.GetVehicle(
      this.selectedVehicle.vehicleId
    );
    this.setSelectedVehicle(<Vehicle>getVehicleResult);
  }

  /**
   * Retorna el vehículo seleccionado.
   * @return {Vehicle}
   */
  getSelectedVehicle(): Vehicle {
    return { ...this.selectedVehicle };
  }

  /**
   * Configura las variables que indican que el vehículo
   * seleccionado tiene semirremolque.
   * @param {Boolean} value Responde a ¿tiene semirremolque?
   */
  setHasTrailer(value: boolean): void {
    this.vehicleHasTrailer = value;
    this.vehicleHasTrailerChanged.next(value);
  }

  /**
   * Elimina el vehículo seleccionado.
   * @param deleteDocuments {boolean}: define si se le pedirá al backend
   * que elimine los documentos de la entidad.
   */
  async deleteSelectedVehicle(deleteDocuments: boolean = true): Promise<void> {
    const vehicleIdentification: string = this.commonsService.getIdentification(
      this.selectedVehicle!.sapId!,
      this.selectedVehicle!.licensePlate
    );
    // 1. Actualizamos para dejar constancia
    // del usuario que pidió la eliminación.
    const updateVehicleInput: UpdateVehicleInput = {
      vehicleId: this.selectedVehicle!.vehicleId,
      updatedBy: this.usersService.getActiveUser().userId,
      comment: `deleteDocuments: ${deleteDocuments}`,
    };

    await this.api
      .UpdateVehicle(updateVehicleInput)
      .then(async (vehicle: UpdateVehicleMutation): Promise<void> => {
        // 2. Se procede con la eliminación.
        const deleteVehicleInput: DeleteVehicleInput = {
          vehicleId: vehicle.vehicleId,
        };

        await this.api
          .DeleteVehicle(deleteVehicleInput)
          .then(async () => {
            this.feedbacksService.showFeedback(
              `Vehículo ${vehicleIdentification} eliminado.`,
              'success'
            );
          })
          .catch((response: any): void => {
            this.feedbacksService.showErrorFeedbacks(
              response,
              `Error al borrar vehículo ${vehicleIdentification}`
            );
          });
      })
      .catch((response: any): void => {
        this.feedbacksService.showErrorFeedbacks(
          response,
          `Error al actualizar vehículo ${vehicleIdentification}`
        );
      });
  }

  // ----------------------------------
  // Métodos para la lista de Vehículos
  // ----------------------------------
  /**
   * Configura la lista de vehículos para referencia de
   * todos los componentes.
   * @param {Vehicle[]} vehicles Lista de vehículos.
   */
  setVehicles(vehicles: Vehicle[]): void {
    this.vehicles = vehicles;
    this.setVehiclesFilter();
    this.vehiclesChanged.next(this.vehicles.slice());
  }

  /**
   * Actualiza la lista de vehículos.
   * @return {Promise}
   */
  async refreshVehicles(): Promise<void> {
    const activeUser: User = this.usersService.getActiveUser();
    const business: string = this.usersService.business.value.toUpperCase();
    let tempListVehicles: Vehicle[] = [];

    // Verificamos el grupo del usuario
    if (activeUser.authGroup.endsWith('_CARRIERS')) {
      // Los transportistas podrán acceder a vehículos donde
      // ellos figuren como Carrier
      tempListVehicles = await this.listVehiclesByCarrier(activeUser);
    } else if (activeUser.authGroup.endsWith('_ADMINS')) {
      // Los administradores podrán acceder a todos los vehículos/conductores
      // asociados a su negocio
      tempListVehicles = await this.listVehiclesByBusiness(business);
    } else if (
      activeUser.authGroup.endsWith('_VIEWERS') ||
      activeUser.authGroup.endsWith('_APPROVERS') ||
      activeUser.authGroup.endsWith('CA_CREATORS') ||
      activeUser.authGroup.endsWith('CA_APPROVERS_N1') ||
      activeUser.authGroup.endsWith('CA_APPROVERS_N2')
    ) {
      // Los visualizadores y aprobadores podrán acceder a todos los vehículos
      // asociados a los centros a los que pertenece el usuario.
      tempListVehicles = await this.listVehiclesByCenter(activeUser, business);
    } else {
      this.feedbacksService.showFeedback(
        `Error obteniendo vehículos. Grupo ${activeUser.authGroup} no tiene regla definida.`,
        'danger'
      );
    }

    this.setVehicles(tempListVehicles.slice());
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Negocio.
   * @param business {string} Negocio.
   * @private
   */
  private async listVehiclesByBusiness(business: string): Promise<Vehicle[]> {
    let listVehiclesResult: VehiclesByBusinessAndVehicleIdQuery;
    listVehiclesResult = await this.api.VehiclesByBusinessAndVehicleId(
      business
    );

    let tempListVehicles: Vehicle[] = <Vehicle[]>listVehiclesResult.items;
    let nextToken: nextToken = listVehiclesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListVehiclesResult: VehiclesByBusinessAndVehicleIdQuery =
        await this.api.VehiclesByBusinessAndVehicleId(
          business,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListVehicles.push(...(<Vehicle[]>loopListVehiclesResult.items));
      nextToken = loopListVehiclesResult.nextToken;
    }
    return tempListVehicles.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Centros.
   * @param activeUser {User} Usuario activo.
   * @param business {string} Negocio.
   * @private
   */
  private async listVehiclesByCenter(
    activeUser: User,
    business: string
  ): Promise<Vehicle[]> {
    let tempListVehicles: Vehicle[] = [];
    const centers: string[] =
      JSON.parse(activeUser.centers || `{"${business}": []}`)[business] || [];

    // Ejecutar todas las peticiones en paralelo
    const vehiclesPromises: Promise<Vehicle[]>[] = centers.map(
      async (center: string): Promise<Vehicle[]> => {
        let listVehiclesResult: VehiclesByCenterAndVehicleIdQuery;

        listVehiclesResult = await this.api.VehiclesByCenterAndVehicleId(
          center
        );
        let vehicles: Vehicle[] = <Vehicle[]>listVehiclesResult.items;

        let nextToken: nextToken = listVehiclesResult.nextToken;

        // Si hay más datos, seguir llamando hasta que no haya nextToken
        while (nextToken) {
          let loopListVehiclesResult: VehiclesByCenterAndVehicleIdQuery =
            await this.api.VehiclesByCenterAndVehicleId(
              center,
              undefined,
              undefined,
              undefined,
              100,
              nextToken
            );
          vehicles.push(...(<Vehicle[]>loopListVehiclesResult.items));
          nextToken = loopListVehiclesResult.nextToken;
        }

        return vehicles;
      }
    );

    // Esperar a que todas las promesas se resuelvan
    const results: Vehicle[][] = await Promise.all(vehiclesPromises);

    // Combinar todos los resultados en una sola lista
    results.forEach((vehicles: Vehicle[]): void => {
      tempListVehicles.push(...vehicles);
    });

    return tempListVehicles.slice();
  }

  /**
   * Retorna la lista de Vehículos usando el GSI de Transportista.
   * @param activeUser {User} Usuario activo.
   * @private
   */
  private async listVehiclesByCarrier(activeUser: User): Promise<Vehicle[]> {
    let listVehiclesResult: VehiclesByCompanyAndVehicleIdQuery;
    listVehiclesResult = await this.api.VehiclesByCompanyAndVehicleId(
      activeUser.company
    );

    let tempListVehicles: Vehicle[] = <Vehicle[]>listVehiclesResult.items;
    let nextToken: nextToken = listVehiclesResult.nextToken;
    // Es posible que la primera query no retorne todos los vehículos.
    while (nextToken) {
      let loopListVehiclesResult: VehiclesByCompanyAndVehicleIdQuery =
        await this.api.VehiclesByCompanyAndVehicleId(
          activeUser.company,
          undefined,
          undefined,
          undefined,
          100,
          nextToken
        );
      tempListVehicles.push(...(<Vehicle[]>loopListVehiclesResult.items));
      nextToken = loopListVehiclesResult.nextToken;
    }
    return tempListVehicles.slice();
  }

  /**
   * Retorna la lista de vehículos.
   * @return {Vehicle[]}
   */
  getVehicles(): Vehicle[] {
    return this.vehicles.slice();
  }

  // ------------------------------------------
  // Métodos para filtro de consultas a AppSync
  // ------------------------------------------
  /**
   * Configura el filtro para las consultas
   * de listas de vehículos y subscripciones.
   * @private
   */
  private setVehiclesFilter(): void {
    let modelFilterInput: filterInputs = this.usersService.getEntitiesFilter();

    this.vehiclesFilterForSubscriptions = <ModelSubscriptionVehicleFilterInput>(
      modelFilterInput
    );
  }

  /**
   * Retorna el filtro para las subscripciones
   * de listas de vehículos.
   * @return {ModelSubscriptionVehicleFilterInput}
   */
  getVehiclesFilterForSubscriptions(): ModelSubscriptionVehicleFilterInput {
    return { ...this.vehiclesFilterForSubscriptions };
  }

  /**
   * Configura el filtro para las subscripciones
   * del vehículo seleccionado.
   */
  private setSelectedVehicleFilterForSubscriptions(): void {
    const modelSubscriptionIDInput: ModelSubscriptionIDInput = {
      eq: this.selectedVehicle.vehicleId,
    };
    this.selectedVehicleFilterForSubscriptions = {
      vehicleId: modelSubscriptionIDInput,
    };
  }

  /**
   * Retorna el filtro para las subscripciones
   * del vehículo seleccionado.
   * @return {ModelSubscriptionVehicleFilterInput}
   */
  getSelectedVehicleFilterForSubscriptions(): ModelSubscriptionVehicleFilterInput {
    return { ...this.selectedVehicleFilterForSubscriptions };
  }

  /**
   * Verifica la existencia de un vehículo.
   * @param {string} vehicleId ID del vehículo.
   */
  async checkVehicleExistence(vehicleId: string): Promise<boolean> {
    const getVehicleResult: GetVehicleQuery = await this.api.GetVehicle(
      vehicleId
    );
    return Boolean(getVehicleResult);
  }
}
