<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Carga masiva de acuerdos comerciales
  </h4>
  <button
    id="details-modal-close"
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="d('Cross')"
  ></button>
</div>
<div class="modal-body">
  <p>
    <strong
      >Puedes descargar un archivo con el formato esperado o cargar su archivo
      si ya lo tiene configurado. Los acuerdos comerciales por carga masiva
      quedarán pendientes de aprobación automáticamente.
    </strong>
  </p>
  <p>
    <strong
      >Los acuerdos comerciales con errores en el proceso de carga serán
      notificados vía correo electrónico y deberá cargarlos nuevamente.</strong
    >
  </p>
  <p>Cargue su formulario</p>

  <form [formGroup]="form" action="submit()">
    <div style="display: flex; justify-content: space-around">
      <div>
        <input
          accept=".xlsx"
          type="file"
          name="archive"
          id="archive"
          (change)="onChangeImageFile($event)"
          class="form-control"
        />
      </div>
      <button
        (click)="submit()"
        [disabled]="form.invalid && disableButton"
        class="btn btn-primary mx-2 float-end"
      >
        Enviar masivamente a liberación
      </button>
    </div>
  </form>
  <div style="display: flex; justify-content: center; padding-top: 15px">
    <p>
      ¿No tiene el formulario?<br />
      Descargue la pantilla
      <a
        download="Acuerdos_Comerciales.xlsx"
        href="/assets/files/Carga_masiva_de_acuerdos_comerciales.xlsx"
        >acá</a
      >
    </p>
  </div>
</div>
