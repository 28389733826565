import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommercialAgreementsService } from '../../commercial-agreements.service';

import { COMMERCIALAGREEMENTSMODALS } from 'src/app/shared/constants/constants';
import {
  CommercialAgreement,
  UpdateCommercialAgreementInput,
} from 'src/app/app-sync.service';
import { UsersService } from 'src/app/pages/users/users.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ValidatorsService } from 'src/app/shared/services/validators.service';
import { FeedbacksService } from 'src/app/shared/feedbacks/feedbacks.service';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css'],
})
export class UpdateComponent implements OnInit {
  title: string = '';
  message: string = '';
  question: string = '';
  hasComment: boolean = false;
  actualDate = new Date();
  @Input() action: 'reject' | 'aproveN1' | 'cancel' | 'aproveN2' = 'aproveN1';
  @Input() List: CommercialAgreement[] = [];
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();
  commentForm: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
  });

  constructor(
    private commercialAgreementsService: CommercialAgreementsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private feedbacksService: FeedbacksService
  ) {}

  ngOnInit(): void {
    let lvlOfAprove = this.action === 'aproveN1' ? '1' : '2';
    switch (this.action) {
      case 'reject':
        this.title = COMMERCIALAGREEMENTSMODALS.REJECT.title;
        this.message = COMMERCIALAGREEMENTSMODALS.REJECT.message.replace(
          '$total',
          this.List.length.toString()
        );
        this.question = COMMERCIALAGREEMENTSMODALS.REJECT.question;
        this.hasComment = COMMERCIALAGREEMENTSMODALS.REJECT.hasComment;
        break;

      case 'cancel':
        this.title = COMMERCIALAGREEMENTSMODALS.CANCEL.title;
        this.message = COMMERCIALAGREEMENTSMODALS.CANCEL.message.replace(
          '$total',
          this.List.length.toString()
        );
        this.question = COMMERCIALAGREEMENTSMODALS.CANCEL.question;
        this.hasComment = COMMERCIALAGREEMENTSMODALS.CANCEL.hasComment;
        break;

      default:
        this.title = COMMERCIALAGREEMENTSMODALS.APROVE.title.replace(
          '$lvl',
          lvlOfAprove
        );
        this.message = COMMERCIALAGREEMENTSMODALS.APROVE.message.replace(
          '$total',
          this.List.length.toString()
        );
        this.question = COMMERCIALAGREEMENTSMODALS.APROVE.question.replace(
          '$lvl',
          lvlOfAprove
        );
        this.hasComment = COMMERCIALAGREEMENTSMODALS.APROVE.hasComment;
        break;
    }
  }

  send() {
    let newStatus = '';
    switch (this.action) {
      case 'reject':
        newStatus = 'Rechazado';
        break;
      case 'cancel':
        newStatus = 'Dado de Baja';
        break;

      case 'aproveN1':
        newStatus = 'Pendiente N2';
        break;

      case 'aproveN2':
        newStatus = 'Aprobado';
        break;
    }
    this.List.forEach((agreement) => {
      agreement.status = newStatus;
      if (this.action === 'reject') {
        agreement.comment = this.commentForm.get('comment')?.value;
      }
      agreement.updatedBy = this.usersService.getActiveUser().email;
      const { __typename, createdAt, updatedAt, ...validAgreement } = agreement;
      this.commercialAgreementsService
        .updateCommercialAgreement(validAgreement)
        .then((response) => {
          this.feedbacksService.showFeedback(
            `Acuerdo Commercial ${
              agreement.sapId + '-' + agreement.agreementId
            } actualizado correctamente al estado: ${newStatus}`,
            'success'
          );
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.feedbacksService.showErrorFeedbacks(
            error,
            `Error al actualizar Acuerdo Comercial: ${
              agreement.sapId + '-' + agreement.agreementId
            }`
          );
        });
    });
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.send();
    this.modalClosed.emit(value);
  }
  get notificationControl() {
    return this.commentForm;
  }
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }
}
