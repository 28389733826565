import { Injectable } from '@angular/core';
import {
  APIService,
  CommercialAgreement,
  ListCommercialAgreementsQuery,
  ModelIDKeyConditionInput,
  UpdateCommercialAgreementInput,
} from 'src/app/app-sync.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class CommercialAgreementsService {
  constructor(private api: APIService, private usersService: UsersService) {}

  /**
   * Obtiene la lista de especificaciones de Acuerdos Comerciales desde el API sin modificar los objetos.
   * @param {string} business Negocio
   * @param {ModelIDKeyConditionInput} modelIDKeyConditionInput Filtro
   * @return {Promise<CommercialAgreement[]>} Lista de objetos CommercialAgreements
   */
  private async getCommercialAgreementsListRaw(
    business: string
  ): Promise<CommercialAgreement[]> {
    let tempListDocumentSpecs: CommercialAgreement[] = [];
    let listDocumentsSpecsResult: ListCommercialAgreementsQuery =
      await this.api.CommercialAgreementsByBusinessAndAgreementId(business);
    tempListDocumentSpecs = <CommercialAgreement[]>(
      listDocumentsSpecsResult.items
    );

    let nextToken = listDocumentsSpecsResult.nextToken;
    // Sigue obteniendo resultados si hay un nextToken
    while (nextToken) {
      let loopListDocumentsSpecsResult: ListCommercialAgreementsQuery =
        await this.api.CommercialAgreementsByBusinessAndAgreementId(
          business,
          {},
          undefined,
          {},
          100,
          nextToken
        );
      tempListDocumentSpecs.push(
        ...(<CommercialAgreement[]>loopListDocumentsSpecsResult.items)
      );
      nextToken = loopListDocumentsSpecsResult.nextToken;
    }

    return tempListDocumentSpecs; // Retorna la lista sin modificar
  }

  /**
   * Obtiene la lista de especificaciones de documentos con un filtro que incluye selectedCenter.
   * @param {string} business Negocio
   * @return {Promise<CommercialAgreement[]>} Lista de objetos CommercialAgreement
   */
  async getCommercialAgreementList(): Promise<CommercialAgreement[]> {
    const business: string = this.usersService.business.value.toUpperCase();
    return this.getCommercialAgreementsListRaw(business);
  }

  async updateCommercialAgreement(
    commercialAgreement: UpdateCommercialAgreementInput
  ) {
    await this.api.UpdateCommercialAgreement(commercialAgreement);
  }
}
