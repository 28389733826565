import { Injectable } from '@angular/core';

import { documentOwner } from '../../shared/types/document-owners';
import { DocumentSpecsObject } from '../../shared/interfaces/document-specs-object';

import {
  APIService,
  DocumentSpecs,
  ListDocumentSpecsQuery,
  ModelIDKeyConditionInput,
  UpdateDocumentSpecsInput,
} from '../../app-sync.service';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private documentsSpecs: DocumentSpecsObject = {};
  private selectedModel: documentOwner = '';

  constructor(private api: APIService, private usersService: UsersService) {}

  /**
   * Define el modelo seleccionado (VEHICLE, DRIVER, etc.).
   * @param {documentOwner} model Modelo seleccionado.
   */
  setSelectedModel(model: documentOwner): void {
    this.selectedModel = model;
  }

  /**
   * Retorna el modelo seleccionado.
   * @return {string}
   */
  getSelectedModel(): string {
    return this.selectedModel.slice();
  }

  /**
   * Genera la lista de especificaciones de documentos.
   * @param {string} business Negocio
   * @param {ModelIDKeyConditionInput} modelIDKeyConditionInput Filtro
   * @private
   */
  private async prepareDocumentsSpecsList(
    business: string,
    modelIDKeyConditionInput: ModelIDKeyConditionInput
  ): Promise<DocumentSpecsObject> {
    let tempDocumentsSpecs: DocumentSpecsObject = {};

    let listDocumentsSpecsResult: ListDocumentSpecsQuery =
      await this.api.ListDocumentSpecs(business, modelIDKeyConditionInput);
    let tempListDocumentSpecs = <DocumentSpecs[]>listDocumentsSpecsResult.items;
    let nextToken = listDocumentsSpecsResult.nextToken;
    // Es posible que la primera query no retorne todas las especificaciones de documentos.
    while (nextToken) {
      let loopListDocumentsSpecsResult: ListDocumentSpecsQuery =
        await this.api.ListDocumentSpecs(
          business,
          modelIDKeyConditionInput,
          {},
          100,
          nextToken
        );
      tempListDocumentSpecs.push(
        ...(<DocumentSpecs[]>loopListDocumentsSpecsResult.items)
      );
      nextToken = loopListDocumentsSpecsResult.nextToken;
    }

    for (const item of tempListDocumentSpecs) {
      const key: string = item.documentSpecsId.substring(
        item.documentSpecsId.indexOf('#') + 1
      );
      tempDocumentsSpecs[key] = item.isMandatory;
    }

    return { ...tempDocumentsSpecs };
  }

  // -----------------------------------------------------------------------
  // Métodos para las configuraciones de documentos de vehículos/conductores
  // -----------------------------------------------------------------------
  /**
   * Define la lista de especificaciones de documentos.
   * @return {Promise}
   */
  async setDocumentsSpecs(): Promise<void> {
    const business: string = this.usersService.business.value.toUpperCase();
    const modelIDKeyConditionInput: ModelIDKeyConditionInput = {
      beginsWith: this.selectedModel + 'S#',
    };

    this.documentsSpecs = await this.prepareDocumentsSpecsList(
      business,
      modelIDKeyConditionInput
    );
    console.log('documentsSpecs: ', this.documentsSpecs);
  }

  /**
   * Retorna la lista de especificaciones de documentos.
   * @return {DocumentSpecsObject}
   */
  getDocumentsSpecs(): DocumentSpecsObject {
    return { ...this.documentsSpecs };
  }

  /**
   * Obtiene la lista de especificaciones de documentos desde el API sin modificar los objetos.
   * @param {string} business Negocio
   * @param {ModelIDKeyConditionInput} modelIDKeyConditionInput Filtro
   * @return {Promise<DocumentSpecs[]>} Lista de objetos DocumentSpecs
   */
  private async getDocumentsSpecsListRaw(
    business: string,
    modelIDKeyConditionInput: ModelIDKeyConditionInput
  ): Promise<DocumentSpecs[]> {
    let tempListDocumentSpecs: DocumentSpecs[] = [];

    let listDocumentsSpecsResult: ListDocumentSpecsQuery =
      await this.api.ListDocumentSpecs(business, modelIDKeyConditionInput);
    tempListDocumentSpecs = <DocumentSpecs[]>listDocumentsSpecsResult.items;

    let nextToken = listDocumentsSpecsResult.nextToken;
    // Sigue obteniendo resultados si hay un nextToken
    while (nextToken) {
      let loopListDocumentsSpecsResult: ListDocumentSpecsQuery =
        await this.api.ListDocumentSpecs(
          business,
          modelIDKeyConditionInput,
          {},
          100,
          nextToken
        );
      tempListDocumentSpecs.push(
        ...(<DocumentSpecs[]>loopListDocumentsSpecsResult.items)
      );
      nextToken = loopListDocumentsSpecsResult.nextToken;
    }

    return tempListDocumentSpecs; // Retorna la lista sin modificar
  }
  /**
   * Obtiene la lista de especificaciones de documentos con un filtro que incluye selectedCenter.
   * @param {string} business Negocio
   * @param {string} selectedModel Modelo seleccionado
   * @param {string} selectedCenter Centro seleccionado
   * @return {Promise<DocumentSpecs[]>} Lista de objetos DocumentSpecs
   */
  async getDocumentsSpecsListWithCenter(
    selectedModel: string,
    selectedCenter: string
  ): Promise<DocumentSpecs[]> {
    const business: string = this.usersService.business.value.toUpperCase();
    const modelIDKeyConditionInput: ModelIDKeyConditionInput = {
      beginsWith: `${selectedModel}S#${selectedCenter}#`,
    };

    return this.getDocumentsSpecsListRaw(business, modelIDKeyConditionInput);
  }

  async updateDocumentsSpecs(DocumentSpecs: UpdateDocumentSpecsInput) {
    await this.api.UpdateDocumentSpecs(DocumentSpecs);
  }
}
