<div class="row">
  <div class="text-center my-5" *ngIf="isGettingUser">
    <app-spinner
      [message]="
        editMode ? 'Cargando formulario' : 'Cargando detalles del usuario'
      "
    ></app-spinner>
  </div>
  <div class="col-xs-12" *ngIf="!isGettingUser">
    <h3>{{ editMode ? "Editar" : "Nuevo" }} Usuario</h3>
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <h4>Datos del Usuario</h4>
        <div class="row">
          <div class="col-sm">
            <label for="email" class="form-label">E-mail</label>
            <div class="form-group">
              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-control immutable-field"
                placeholder="Ejemplo: micorreo@empresa.com"
              />
            </div>
            <div *ngIf="showHelper(userControl.get('email'))">
              <small class="form-text text-muted">
                {{ helperMessages(userControl.get("email")) }}
              </small>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm-6">
            <label for="firstName" class="form-label">Nombre</label>
            <div class="form-group">
              <input
                type="text"
                id="firstName"
                formControlName="firstName"
                class="form-control immutable-field"
                placeholder="Ejemplo: Pedro"
                appInputTrim
              />
            </div>
          </div>
          <div class="col-sm-6">
            <label for="lastName" class="form-label">Apellido</label>
            <div class="form-group">
              <input
                type="text"
                id="lastName"
                formControlName="lastName"
                class="form-control immutable-field"
                placeholder="Ejemplo: Pérez"
                appInputTrim
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div
            class="col-sm-4 text-center border rounded mx-2"
            *ngIf="business === 'FOREIGN'"
          >
            <p class="pt-1">Extranjero</p>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isForeignYes"
                formControlName="isForeign"
                class="form-check-input"
                value="true"
                (change)="onIsForeignChange()"
              />
              <label for="isForeignYes" class="form-check-label">Sí</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="radio"
                id="isForeignNo"
                formControlName="isForeign"
                class="form-check-input"
                value="false"
                (change)="onIsForeignChange()"
              />
              <label for="isForeignNo" class="form-check-label">No</label>
            </div>
          </div>
          <div class="col-sm">
            <label for="user-rut" class="form-label">
              {{ newUserIsForeign ? "Cédula de Identidad" : "RUT" }}
            </label>
            <div class="form-group">
              <input
                type="text"
                id="user-rut"
                formControlName="rut"
                class="form-control immutable-field"
                placeholder="Ejemplo: {{
                  newUserIsForeign ? '12.345.678' : '12.345.678-9'
                }}"
                appInputUppercase
              />
            </div>
            <ng-container
              *ngIf="
                userForm.controls['rut'].hasError('invalidRUT') &&
                  userForm.controls['rut'].touched;
                else helperText
              "
            >
              <span>{{ userForm.controls['rut'].errors?.['invalidRUT'] }}</span>
            </ng-container>
            <ng-template #helperText>
              <small
                id="user-rut-helper"
                class="form-text text-muted"
                *ngIf="!editMode"
                >Con puntos {{ newUserIsForeign ? "" : "y guión" }}</small
              >
            </ng-template>
          </div>
          <div class="col-sm">
            <label for="phone" class="form-label">Teléfono</label>
            <div class="form-group">
              <input
                type="text"
                id="phone"
                formControlName="phone"
                class="form-control immutable-field"
                placeholder="Ejemplo: +56987654321"
              />
            </div>
            <small id="rut" class="form-text text-muted" *ngIf="!editMode"
              >Con código de país</small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm">
            <div class="form-group">
              <label for="company" class="form-label">Empresa</label>
              <select
                id="company"
                formControlName="company"
                class="form-select"
                (change)="onCompanyChange()"
              >
                <option
                  *ngFor="let copec of master.COPEC"
                  [ngValue]="copec.valueId"
                  selected
                >
                  {{ copec.valueToDisplay }}
                </option>
                <option
                  *ngFor="let consultant of master.CONSULTANTS"
                  [ngValue]="consultant.valueId"
                >
                  {{ consultant.valueToDisplay }}
                </option>
                <option
                  *ngFor="let carrier of master.CARRIERS"
                  [ngValue]="carrier.valueId"
                >
                  {{ carrier.valueToDisplay }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-sm">
            <label for="authGroup" class="form-label">Grupo</label>
            <select
              id="authGroup"
              formControlName="authGroup"
              class="form-select"
              (change)="onAuthGroupChange()"
            >
              <option value="" selected>Seleccionar...</option>
              <option
                *ngFor="let group of validUserGroups"
                [ngValue]="group.valueId"
              >
                {{ group.valueToDisplay }}
              </option>
            </select>
          </div>
        </div>
        <div class="container-fluid" *ngIf="accessToBusiness">
          <hr />
          <h5 class="my-3">
            <i class="material-symbols-outlined align-middle">cases</i>
            Acceso a Negocios
          </h5>
          <div class="row" formArrayName="hasAccessTo">
            <div
              id="user-has-access-to"
              *ngFor="let businessControl of businessesControl; let i = index"
              [formGroupName]="i"
            >
              <div class="col-sm-6">
                <label class="form-label">
                  <input
                    type="checkbox"
                    id="hasAccessToBusiness{{ i }}"
                    formControlName="hasAccess"
                    [value]="businessControl.get('hasAccess')!.value"
                    (change)="onBusinessChange(i)"
                  />
                  {{
                    businessControl.get("businessName")!.value
                      | master : "BUSINESSES"
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" *ngIf="groupWithCenterAccessSelected">
          <hr />
          <h5 class="my-3">
            <i class="material-symbols-outlined align-middle">warehouse</i>
            Centros
          </h5>
          <div class="text-center my-5" *ngIf="isGettingCenters">
            <app-spinner message="Cargando centros"></app-spinner>
          </div>
          <div class="row" *ngIf="!isGettingCenters">
            <div id="users-centers-all" class="col-sm-4">
              <label class="form-label">
                <input
                  type="checkbox"
                  id="selectAll"
                  formControlName="selectAll"
                  [value]="selectAll"
                  (change)="selectAllModels()"
                />
                Seleccionar Todo
              </label>
            </div>
          </div>
          <div
            class="row border-top py-2"
            formArrayName="centers"
            *ngIf="!isGettingCenters"
          >
            <div
              id="user-centers"
              class="col-sm-4"
              *ngFor="let centerControl of centersControl; let i = index"
              [formGroupName]="i"
            >
              <label class="form-label">
                <input
                  type="checkbox"
                  id="hasAccessToCenter{{ i }}"
                  formControlName="hasAccess"
                  [value]="centerControl.get('hasAccess')!.value"
                />
                {{
                  centerControl.get("centerName")!.value | master : "CENTERS"
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xs-12">
          <button
            id="user-edit-cancel"
            type="button"
            class="btn btn-outline-danger"
            (click)="onCancel()"
          >
            Cancelar
          </button>
          <button
            id="envasado-edit-show"
            type="button"
            class="btn btn-outline-secondary mx-2"
            (click)="showForm()"
            *ngIf="isDev"
          >
            Log Form
          </button>
          <button
            id="user-edit-submit"
            type="submit"
            class="btn btn-outline-primary mx-2 float-end"
            [disabled]="!userForm.valid"
          >
            {{ editMode ? "Guardar" : "Crear Usuario" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
