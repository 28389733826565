import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-centers-selecction-modal',
  templateUrl: './centers-selecction-modal.component.html',
  styleUrls: ['./centers-selecction-modal.component.css'],
})
export class CentersSelecctionModalComponent implements OnInit {
  @Input() centers: any = {};
  @Input() selectedCenter: any = {};
  @Input() form: FormGroup = new FormGroup({});
  @Output() modalDismissed: EventEmitter<string> = new EventEmitter<string>();
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.form.get('selectAll')?.valueChanges.subscribe((checked) => {
      this.toggleSelectAll(checked);
    });
  }

  toggleSelectAll(selectAll: boolean): void {
    const centerArray = this.form.get('centers') as FormArray;
    centerArray.controls.forEach((control) => {
      if (control.value.center.valueToDisplay !== this.selectedCenter)
        control.get('selected')?.setValue(selectAll);
    });
  }

  isAllSelected(): boolean {
    const centerArray = this.form.get('centers') as FormArray;
    return centerArray.controls.every(
      (control) => control.get('selected')?.value
    );
  }

  toggleSelectAllCheckbox(): void {
    const allSelected = this.isAllSelected();
    this.form.get('selectAll')?.setValue(allSelected, { emitEvent: false });
  }

  toggleIndividual(): void {
    this.toggleSelectAllCheckbox();
  }

  get centersControls(): FormArray {
    return this.form.get('centers') as FormArray;
  }

  /**
   * Función de desecho del modal.
   * @param {string} value Tecla con que fue desechado.
   */
  d(value: string): void {
    this.modalDismissed.emit(value);
  }

  /**
   * Función de cierre del modal.
   * @param {string} value Tecla con que fue cerrado.
   */
  c(value: string): void {
    this.modalClosed.emit(value);
  }
}
