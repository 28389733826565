<hr />
<div class="container-fluid my-2">
  <div class="col-sm-4">
    <h6>Acuerdos Comerciales</h6>
  </div>
  <div class="row">
    <div class="filters-container">
      <form action=""></form>

      <div ngbDropdown class="d-inline-block">
        <button
          type="button"
          class="btn btn-outline-primary dropdownButton"
          id="dropdownActionsAgreements"
          ngbDropdownToggle
        >
          Acciones
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownActionsAgreements">
          <a
            id="ActionsAgreements"
            ngbDropdownItem
            [disabled]="!isButtonActive('aprove')"
            (click)="showUpdateListModal('aprove')"
          >
            Aprobar</a
          >
          <a
            id="ActionsAgreements"
            ngbDropdownItem
            [disabled]="!isButtonActive('reject')"
            (click)="showUpdateListModal('reject')"
          >
            Rechazar</a
          >
          <a id="ActionsAgreements" ngbDropdownItem> Crear desde existente</a>
          <a
            id="ActionsAgreements"
            ngbDropdownItem
            (click)="showUpdateListModal('cancel')"
          >
            Dar de baja</a
          >
          <hr class="dropdown-divider" />
          <a id="ActionsAgreements" ngbDropdownItem> Crear</a>
          <a
            id="ActionsAgreements"
            ngbDropdownItem
            [disabled]="!isActiveUpload()"
            (click)="showUploadModal()"
          >
            Crear masivamente</a
          >
          <hr class="dropdown-divider" />
          <a id="ActionsAgreements" ngbDropdownItem> Descargar</a>
        </div>
      </div>
    </div>
    <div class="table-responsive-sm" *ngIf="!isGettingAgreements">
      <form [formGroup]="commercialFormGroup">
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table display nowrap"
          style="width: 100%"
        >
          <caption></caption>
          <thead>
            <tr>
              <th><input type="checkbox" name="selectAll" id="selectAll" /></th>
              <th class="text-center">Vehículo</th>
              <th class="text-center">Centro</th>
              <th class="text-center">Tipo Vehículo</th>
              <th class="text-center">Transportista</th>
              <th class="text-center">Capacidad</th>
              <th class="text-center">Acuerdo</th>
              <th class="text-center">Monto Garantizado</th>
              <th class="text-center">% Variable</th>
              <th class="text-center">Fecha Inicio</th>
              <th class="text-center">Fecha Fin</th>
              <th class="text-center">Solicitante</th>
              <th class="text-center">Estado</th>
              <th class="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody formArrayName="commercialAgreementsForm">
            <tr
              *ngFor="
                let commercialAgreement of acControls.controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <td class="text-center">
                <input
                  formControlName="selected"
                  type="checkbox"
                  name="select"
                  id="select"
                />
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.sapId | sapId }} -
                {{ commercialAgreement.value.AC.entityId }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.center | master : "CENTERS" }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.modelTypeName }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.carrier }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.capacity }} M3
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.type }}
              </td>
              <td
                class="text-center"
                *ngIf="commercialAgreement.value.AC?.guaranteedUoM === 'USD'"
              >
                {{ commercialAgreement.value.AC.guaranteed | currency }}
              </td>
              <td
                class="text-center"
                *ngIf="commercialAgreement.value.AC?.guaranteedUoM === 'CLP'"
              >
                {{
                  commercialAgreement.value.AC.guaranteed
                    | currency : "$" : "symbol" : "1.0-0"
                }}
              </td>
              <td class="text-center">
                {{
                  (commercialAgreement.value.AC.additionalPercentage ?? 0) * 100
                    | number : "1.0-0"
                }}%
              </td>
              <td class="text-center">
                {{
                  commercialAgreement.value.AC.startTime | date : "dd/MM/yyyy"
                }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.endTime | date : "dd/MM/yyyy" }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.createdBy }}
              </td>
              <td class="text-center">
                {{ commercialAgreement.value.AC.status }}
              </td>
              <td>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                >
                  <a id="preview-evaluation" title="Visualizar">
                    <span class="material-symbols-outlined text-secondary"
                      >preview</span
                    >
                  </a>
                </label>
                <label
                  class="custom-file-upload pt-2 mx-1"
                  style="cursor: pointer"
                >
                  <a id="download-evaluation" title="Descargar">
                    <span class="material-symbols-outlined text-secondary"
                      >download</span
                    >
                  </a>
                </label>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>

<!-- Modals -->
<ng-template #uploadModal let-c="close" let-d="dismiss">
  <app-upload
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-upload>
</ng-template>

<ng-template #updateListModal let-c="close" let-d="dismiss">
  <app-update
    [action]="action"
    [List]="selectedAgreements"
    (modalDismissed)="d($event)"
    (modalClosed)="c($event)"
  ></app-update>
</ng-template>
