import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../auth/auth.guard';
import { MasterResolver } from '../master/master.resolver';
import { CommercialAgreementsComponent } from './commercial-agreements.component';

const routes: Routes = [
  {
    path: ':business/commercial-agreements',
    component: CommercialAgreementsComponent,
    canActivate: [AuthGuard],
    resolve: [MasterResolver],
    //children: [{ path: '', component:  }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommercialAgreementsRoutingModule {}
