import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { appConstants } from '../../../shared/constants/constants';
import { FormValidators } from '../../../shared/interfaces/form-validators';
import { DocumentSpecsObject } from '../../../shared/interfaces/document-specs-object';

import {
  APIService,
  CreateTanqueInput,
  CreateTanqueMutation,
  CreateNotificationInput,
  Document,
  Tanque,
  UpdateTanqueInput,
} from '../../../app-sync.service';
import { DocumentsService } from '../../documents/documents.service';
import { FeedbacksService } from '../../../shared/feedbacks/feedbacks.service';
import { MasterService } from '../../master/master.service';
import { SettingsService } from '../../settings/settings.service';
import { UsersService } from '../../users/users.service';
import { ValidatorsService } from '../../../shared/services/validators.service';
import { TanquesService } from '../tanques.service';
import { FormsService } from '../../../shared/services/forms.service';
import { DocumentFromForms } from '../../../shared/interfaces/document-from-forms';
import { EditionsService } from '../../../shared/services/editions.service';
import { ArchivesService } from '../../../shared/services/archives.service';
import { CommonsService } from '../../../shared/services/commons.service';

@Component({
  selector: 'app-tanque-edit',
  templateUrl: './tanque-edit.component.html',
  styleUrls: ['./tanque-edit.component.css'],
})
export class TanqueEditComponent implements OnInit, OnDestroy {
  entityName: string = 'Tanque';
  selectedTanque: Tanque | undefined = undefined;
  tanqueIdentification: string = '';
  equipType: string = '';
  equipTypeToDisplay: string = '';
  currentYear: number = new Date().getFullYear();
  editMode: boolean = true;
  archiveMode: boolean = false;
  isAdmin: boolean = false;
  isCarrier: boolean = false;
  isGettingTanque: boolean = false;
  master: any = {};
  masterDisplayMap: any = {};
  documentsSpecs: DocumentSpecsObject = {};
  selectedCenter: string = '';
  tanqueForm: FormGroup;
  tanqueId: string = '';
  isDev: boolean = environment.env === 'dev' || environment.env === 'sandbox';
  business: string;
  editionId: string = '';
  tanqueVolumeSelected: string = '';
  tanquesVolumes: { valueId: string; valueToDisplay: string }[] = [];
  tanqueContainersNumberSelected: string = '';
  tanquesContainersNumbers: { valueId: string; valueToDisplay: string }[] = [];
  allTanquesContainersNumbers: {
    [index: string]: { valueId: string; valueToDisplay: string }[];
  } = {};
  tanquesCapacities: { valueId: string; valueToDisplay: string }[] = [];
  isNotForeign: boolean = false;

  get documentsControl() {
    return (<FormArray>this.tanqueForm.get('documents')).controls;
  }

  get disableSubmit() {
    return (
      this.tanqueForm.invalid ||
      this.tanqueForm.pristine ||
      this.tanqueForm.untouched
    );
  }

  constructor(
    private api: APIService,
    private documentsService: DocumentsService,
    private feedbacksService: FeedbacksService,
    private masterService: MasterService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private validatorsService: ValidatorsService,
    private tanquesService: TanquesService,
    private formsService: FormsService,
    private editionsService: EditionsService,
    private archivesService: ArchivesService,
    private commonsService: CommonsService
  ) {
    this.tanqueForm = new FormGroup({
      carrier: new FormControl(null),
      licensePlate: new FormControl(null),
      brand: new FormControl(null),
      model: new FormControl(null),
      year: new FormControl(null),
      color: new FormControl(null),
      type: new FormControl(null),
      capacity: new FormControl(null),
      subBusiness: new FormControl(null),
      center: new FormControl(null),
      gpsImei: new FormControl(null),
      cameraImei: new FormControl(null),
      forMining: new FormControl(null),
      hasRanchAccreditation: new FormControl(null),
      isSpot: new FormControl(null),
      bulkheadType: new FormControl(null),
      hasPump: new FormControl(null),
      documents: this.formsService.documentsFormArrayInitialization(),
      volume: new FormControl(null),
      height: new FormControl(null),
      width: new FormControl(null),
      length: new FormControl(null),
      MOPCode: new FormControl(null),
      containersNumber: new FormControl(null),
    });
    this.business = this.usersService.business.value.toUpperCase();
  }

  ngOnInit() {
    this.isGettingTanque = true;
    this.isAdmin = this.usersService.isAdmin;
    this.isCarrier = this.usersService.isCarrier;
    this.master = this.masterService.getMaster();
    this.masterDisplayMap = this.masterService.getMasterDisplayMap();
    this.archiveMode = this.archivesService.getArchiving();
    this.selectedTanque = this.tanquesService.getSelectedTanque();
    this.tanqueIdentification = this.commonsService.getIdentification(
      this.selectedTanque.sapId!,
      this.selectedTanque.licensePlate
    );

    this.equipType = this.master['TANQUES#TYPES'][0].valueId;
    this.equipTypeToDisplay =
      this.masterDisplayMap['TANQUES#TYPES'][this.equipType];

    this.masterService.setValidVolumes('TANQUE');
    this.tanquesVolumes = this.masterService.getValidVolumes('TANQUE');
    this.allTanquesContainersNumbers =
      this.masterService.getValidContainersNumber('TANQUE');

    this.route.params.subscribe(async (params: Params): Promise<void> => {
      // Si la ruta contiene un ID de tanque
      // estamos en modo edición
      this.editMode = params['id'] != null;
      this.tanqueId = params['id'];

      this.initTanqueForm();
      if (!this.editMode) {
        if (this.settingsService.getSelectedModel() !== 'TANQUE') {
          console.log('Loading document specifications');
          this.settingsService.setSelectedModel('TANQUE');
          await this.settingsService.setDocumentsSpecs();
        } else {
          console.log('Reusing document specifications');
        }
        this.documentsSpecs = this.settingsService.getDocumentsSpecs();
      } else {
        this.editionId = `${this.business}#TANQUE#${this.tanqueId}`;
        await this.editionsService.createOrUpdateEdition(this.editionId);
      }

      // Ordenamos alfabéticamente el formulario de editar las propiedades
      this.documentsControl.sort((a, b) => {
        const documentNameA = a.value.documentName.toLowerCase();
        const documentNameB = b.value.documentName.toLowerCase();
        return documentNameA.localeCompare(documentNameB);
      });

      this.isGettingTanque = false;
    });
  }

  /* ------------------------------------------- */
  /* - Métodos para la creación del Formulario - */
  /* ------------------------------------------- */
  /**
   * Inicializa el formulario para crear o
   * editar un tanque.
   * @private
   */
  private initTanqueForm(): void {
    // Inicialización del Formulario
    // Por default, los campos estarán vacíos.
    // Si el usuario es Transportista el campo carrier campo estará seleccionado.
    const editingOrArchiving: boolean = this.editMode || this.archiveMode;
    let initialTanque: Tanque = {
      ...appConstants.tanque.initialization,
      carrier: appConstants.carrier.initialization,
    };
    let tanqueDocuments: FormArray =
      this.formsService.documentsFormArrayInitialization();

    // Si es transportista la única opción de carrier es él mismo.
    initialTanque.carrier.carrierId = this.isCarrier
      ? this.usersService.getActiveUser().company
      : '';
    // El tipo de equipo es un valor fijo.
    initialTanque.type = this.equipType;

    if (editingOrArchiving) {
      // En modo de Edición: cargamos los valores de los campos
      // según el tanque escogido.
      // En modo Archivar: cargamos los valores de los campos según
      // el tanque archivado (que sigue siendo el seleccionado).
      initialTanque = this.selectedTanque!;
      const selectedTanqueDocuments: Document[] =
        this.documentsService.getDocuments();
      const capacityAsArray: string[] = initialTanque.capacity
        .replace(`${this.business}_`, '')
        .split('#');
      this.tanqueVolumeSelected = capacityAsArray[0];
      this.tanquesContainersNumbers =
        this.allTanquesContainersNumbers[this.tanqueVolumeSelected];
      const containerCount: number = capacityAsArray.length - 1;
      this.tanqueContainersNumberSelected =
        containerCount === 0 ? '' : containerCount.toString();
      this.setTanquesCapacities();

      let documentMasterValueIdsList: string[] = [];
      tanqueDocuments.removeAt(0);
      for (let document of selectedTanqueDocuments) {
        tanqueDocuments.push(
          this.formsService.createDocumentControlFromDocument(
            document,
            !this.archiveMode
          )
        );
        documentMasterValueIdsList.push(document.masterValueId);
      }

      // Agregamos al formulario los documentos que existen en el maestro y que no
      // han sido cargados hasta el momento.
      tanqueDocuments = this.setAdditionalDocumentsFormGroup(
        initialTanque.center,
        tanqueDocuments,
        documentMasterValueIdsList
      );
    } else {
      // En modo Creación: Creamos un FormControl por cada documento
      // requerido según el Maestro
      tanqueDocuments = this.setInitialDocumentsFormGroup('');
    }

    // Inicialización del formulario
    this.tanqueForm = new FormGroup({
      carrier: new FormControl(
        { value: initialTanque.carrier.carrierId, disabled: this.editMode },
        Validators.required
      ),
      licensePlate: new FormControl(
        {
          value: initialTanque.licensePlate,
          disabled: this.disableLicensePlate(),
        },
        [
          Validators.required,
          this.validatorsService.licensePlatePatternValidator(this.business),
        ],
        [this.validatorsService.licensePlateInUseValidator()]
      ),
      brand: new FormControl(
        { value: initialTanque.brand, disabled: this.editMode },
        Validators.required
      ),
      model: new FormControl(
        { value: initialTanque.model, disabled: this.editMode },
        Validators.required
      ),
      year: new FormControl(
        {
          value: initialTanque.year === 0 ? null : initialTanque.year,
          disabled: this.editMode,
        },
        [Validators.required, Validators.pattern(appConstants.regex.year)]
      ),
      color: new FormControl(
        { value: initialTanque.color, disabled: this.editMode },
        Validators.required
      ),
      type: new FormControl(
        { value: initialTanque.type, disabled: this.editMode },
        Validators.required
      ),
      volume: new FormControl(
        {
          value: this.tanqueVolumeSelected,
          disabled: this.editMode,
        },
        Validators.required
      ),
      containersNumber: new FormControl(
        {
          value: this.tanqueContainersNumberSelected,
          disabled: this.editMode,
        },
        Validators.required
      ),
      capacity: new FormControl(
        { value: initialTanque.capacity, disabled: this.editMode },
        Validators.required
      ),
      center: new FormControl(
        { value: initialTanque.center, disabled: this.editMode },
        Validators.required
      ),
      subBusiness: new FormControl(
        { value: initialTanque.subBusiness, disabled: this.editMode },
        Validators.required
      ),
      gpsImei: new FormControl({
        value: initialTanque.gpsImei,
        disabled: this.editMode,
      }),
      MOPCode: new FormControl({
        value: initialTanque.MOPCode,
        disabled: this.editMode,
      }),
      cameraImei: new FormControl({
        value: initialTanque.cameraImei,
        disabled: this.editMode,
      }),
      forMining: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialTanque.forMining
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      hasRanchAccreditation: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialTanque.hasRanchAccreditation
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      isSpot: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialTanque.isSpot
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      bulkheadType: new FormControl(
        { value: initialTanque.bulkheadType, disabled: this.editMode },
        Validators.required
      ),
      hasPump: new FormControl(
        {
          value: this.formsService.radioButtonsTypeParsing(
            editingOrArchiving,
            initialTanque.hasPump
          ),
          disabled: this.editMode,
        },
        Validators.required
      ),
      height: new FormControl(
        {
          value: this.checkNumberValue(initialTanque.height),
          disabled: this.editMode,
        },
        Validators.required
      ),
      width: new FormControl(
        {
          value: this.checkNumberValue(initialTanque.width),
          disabled: this.editMode,
        },
        Validators.required
      ),
      length: new FormControl(
        {
          value: this.checkNumberValue(initialTanque.length),
          disabled: this.editMode,
        },
        Validators.required
      ),
      documents: tanqueDocuments,
    });
  }

  /**
   * Define el arreglo de controles de documentos para el formulario.
   * @param {string} center Centro al que pertenece el tanque.
   * @return {FormArray}
   * @private
   */
  private setInitialDocumentsFormGroup(center: string): FormArray {
    let tanqueDocuments = this.formsService.documentsFormArrayInitialization();

    let documentTypes = this.master['TANQUES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    tanqueDocuments.removeAt(0);
    for (let document of documentTypes) {
      const validators: FormValidators = this.getValidators(
        document.valueId,
        center
      );
      tanqueDocuments.push(
        this.formsService.createDocumentControlFromMasterWithValidators(
          document,
          validators
        )
      );
    }

    return tanqueDocuments;
  }

  /**
   * Dado un conjunto de documentos, agrega los controles de documentos
   * faltantes según el maestro, para el formulario.
   * @param {string} center Centro al que pertenece el tanque.
   * @param {FormArray} tanqueDocuments Arreglo de controles de documentos.
   * @param {string[]} documentMasterValueIdsList Lista de Ids de documentos incluídos en el tanqueDocuments.
   * @return {FormArray}
   * @private
   */
  private setAdditionalDocumentsFormGroup(
    center: string,
    tanqueDocuments: FormArray,
    documentMasterValueIdsList: string[]
  ): FormArray {
    let documentTypes = this.master['TANQUES#DOCUMENT_TYPES'];
    documentTypes.sort(function (a: any, b: any) {
      let x = a.valueToDisplay.toLowerCase();
      let y = b.valueToDisplay.toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    for (let document of documentTypes) {
      // Se agregan solo los documentos que no se encuentren ya en el arreglo de controles.
      if (documentMasterValueIdsList.indexOf(document.valueId) === -1) {
        const validators: FormValidators = this.getValidators(
          document.valueId,
          center
        );
        tanqueDocuments.push(
          this.formsService.createDocumentControlFromMasterWithValidators(
            document,
            validators
          )
        );
      }
    }

    return tanqueDocuments;
  }

  /**
   * Crea un objeto con las funciones de validación para cada uno
   * de los controles del formulario.
   * @param {string }center Centro al que pertenece el tanque.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {FormValidators}
   * @private
   */
  private getValidators(
    masterValueId: string,
    center: string = ''
  ): FormValidators {
    const isMandatory: boolean =
      this.documentsSpecs[center + '#' + masterValueId];
    return this.validatorsService.getDocumentValidatorForm(center, isMandatory);
  }

  /* ---------------------------------------- */
  /* - Métodos de validación del Formulario - */
  /* ---------------------------------------- */
  /**
   * Determina, a través del servicio de validadores,
   * si debe mostrar la ayuda de un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  showHelper(control: AbstractControl<any, any> | null): boolean | undefined {
    return this.validatorsService.showHelper(control);
  }

  /**
   * Determina si un control del formulario es válido.
   * @param {AbstractControl} control Control del formulario.
   * @return {Boolean}
   */
  invalidControl(control: AbstractControl<any, any> | null): boolean {
    return !control?.valid;
  }

  /**
   * Consulta el mensaje de ayuda para un control del formulario.
   * @param {AbstractControl} control Control del formulario.
   * @return {string} Ayuda para el usuario.
   */
  helperMessages(control: AbstractControl<any, any> | null): string {
    return this.validatorsService.helperMessages(control);
  }

  /* --------------------------------------- */
  /* - Métodos que modifican el Formulario - */
  /* --------------------------------------- */
  /**
   * Actualiza la obligatoriedad de los documentos
   * según el centro seleccionado.
   */
  onTanqueCenterChange(): void {
    if (this.selectedCenter === this.tanqueForm.get('center')?.value) {
      // No cambia el formulario
      console.log('Cambio de centro no altera el formulario');
    } else {
      // Actualizamos los documentos requeridos según el centro.
      this.selectedCenter = this.tanqueForm.get('center')?.value;
      console.log('Actualizamos los documentos requeridos según el centro');
      const tanqueDocuments = this.setInitialDocumentsFormGroup(
        this.selectedCenter
      );
      this.tanqueForm.removeControl('documents');
      this.tanqueForm.setControl('documents', tanqueDocuments);
    }
  }

  /**
   * Asigna el archivo al control documentSourceFile
   * del documento.
   * @param {Event} event Evento input.
   * @param {number} index Índice del documento en el arreglo documents.
   */
  onChangeDocumentFile(event: Event, index: number): void {
    // Asignamos el archivo al FormControl 'documentSourceFile'
    const target = event.target as HTMLInputElement;
    if (target.files!.length > 0) {
      const file: File = target.files![0];
      (<FormArray>this.tanqueForm.get('documents'))
        .at(index)
        .patchValue({ documentSourceFile: file });
    }
  }

  /**
   * Actualiza la variable tanqueVolumeSelected cuyo valor
   * es el volumen del tanque seleccionado en el formulario.
   */
  onTanqueVolumeChange(): void {
    this.tanqueVolumeSelected = this.tanqueForm.get('volume')?.value;
    // Configuración de opciones de compartimentos.
    this.tanquesContainersNumbers =
      this.allTanquesContainersNumbers[this.tanqueVolumeSelected];
    // Reseteo de compartimentos seleccionados.
    this.tanqueContainersNumberSelected = '';
    this.tanqueForm
      .get('containersNumber')
      ?.reset(this.tanqueContainersNumberSelected);
    // Reseteo de capacidad.
    this.tanqueForm.get('capacity')?.reset('');
  }

  /**
   * Actualiza la variable tanquesContainersNumberSelected cuyo valor
   * es el número de contenedores del tanque seleccionado en el formulario.
   */
  onTanqueContainersNumberChange(): void {
    this.tanqueContainersNumberSelected =
      this.tanqueForm.get('containersNumber')?.value;
    // configura las opciones de capacidades
    this.setTanquesCapacities();

    // Reseteo de capacidad.
    this.tanqueForm.get('capacity')?.reset('');
  }

  /**
   * Configura la lista de capacidades compatibles
   * con las selecciones del usuario.
   * Deben estar definidos tanqueVolumeSelected
   * y tanqueContainersNumberSelected
   * @private
   */
  private setTanquesCapacities() {
    this.tanquesCapacities = [];
    for (const capacity of this.master['TANQUES#CAPACITIES']) {
      const hasTheVolumen: boolean = capacity.valueId.startsWith(
        `${this.business}_${this.tanqueVolumeSelected}`
      );
      const hasTheContainerNumber: boolean =
        (capacity.valueId.split('#').length - 1).toString() ===
        this.tanqueContainersNumberSelected;

      if (hasTheVolumen && hasTheContainerNumber) {
        this.tanquesCapacities.push(capacity);
      }
    }
  }

  /**
   * Retorna la fuente de la imagen según la capacidad seleccionada.
   * @return {string} Ruta a la imagen
   */
  capacityImage(): string {
    let srcPath: string = '../../../../assets/images/capacities/capacity-';
    if (this.tanqueForm.get('capacity')?.value !== '') {
      let imageId: string = this.tanqueForm.get('capacity')?.value;
      srcPath += `${imageId.replace(this.business + '_', '')}`;
    } else if (this.tanqueContainersNumberSelected !== '') {
      srcPath += `${this.tanqueVolumeSelected}m3-${this.tanqueContainersNumberSelected}c`;
    } else if (this.tanqueVolumeSelected !== '') {
      srcPath += `${this.tanqueVolumeSelected}m3-1c`;
    } else {
      srcPath += 'unknown';
    }
    return (srcPath + '.jpg').replaceAll('#', '%23');
  }

  /**
   * Retorna el ID del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} ID del transportista.
   */
  carrierValueId(): string {
    return this.usersService.getActiveUser().company;
  }

  /**
   * Retorna el nombre del Transportista al que pertenece un usuario
   * conductor o transportista.
   * @return {string} Nombre del transportista.
   */
  carrierValueToDisplay(): string {
    const company: string = this.usersService.getActiveUser().company;
    return this.masterDisplayMap['CARRIERS'][company];
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * requiere fechas de emisión y vencimiento.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isDatelessDocument(masterValueId: string): boolean {
    return this.documentsService.isDatelessDocument(masterValueId);
  }

  /**
   * Consulta, a través del servicio de documentos, si un tipo de documento
   * es obligatorio para un centro.
   * @param {string} masterValueId ID del tipo de documento en el maestro.
   * @return {boolean}
   */
  isMandatoryDocument(masterValueId: string): boolean {
    const center = this.tanqueForm.get('center')?.value;
    if (this.documentsSpecs[center + '#' + masterValueId]) {
      return this.documentsSpecs[center + '#' + masterValueId];
    } else {
      return false;
    }
  }

  /**
   * Determina si se debe deshabilitar el input
   * para la patente.
   */
  private disableLicensePlate(): boolean {
    return (
      this.editMode ||
      (this.archiveMode && !this.archivesService.hasToChangeID())
    );
  }

  /* ------------------------------- */
  /* - Métodos asociados a botones - */
  /* ------------------------------- */
  /**
   * Crea o edita un tanque.
   */
  async onSubmit(): Promise<void> {
    const newTanque = this.tanqueForm.value;
    const documentList: DocumentFromForms[] =
      this.tanqueForm.value.documents.slice();

    if (this.disableLicensePlate()) {
      // Si la patente fue deshabilitada se asigna acá.
      newTanque['licensePlate'] = this.selectedTanque!.licensePlate;
    }

    console.log('newTanque', newTanque);
    console.log('documentList', documentList);

    const uploadDateYYYYMMDD = new Date()
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '');
    const uploadDateTimestamp = Math.floor(Date.now() / 1000).toString();

    if (this.editMode) {
      // Chequeamos si al menos un documento ha sido editado.
      // De lo contrario no se actualiza el tanque.
      let tanqueEdited: boolean = false;
      documentList.forEach((document: any) => {
        tanqueEdited = tanqueEdited || Boolean(document.documentSourceFile);
        console.log(tanqueEdited);
      });
      if (!tanqueEdited) {
        this.feedbacksService.showFeedback(
          'Debe adjuntar al menos un documento para editar el tanque',
          'danger'
        );
        return;
      }

      // Nota: En el modo edición los ID fueron fijados al momento de iniciar el formulario.
      this.feedbacksService.showFeedback(
        'Actualizando tanque: ' + this.tanqueId,
        'info'
      );

      await this.notifyEdition(uploadDateTimestamp);

      // En modo edición, solo pueden editarse los documentos
      // Sin embargo, el tanque vuelve a quedar en estado de "Por Aprobar"
      // 1. Se cambia en estatus del tanque
      const updateTanqueInput: UpdateTanqueInput = {
        tanqueId: this.tanqueId,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
        updatedBy: this.usersService.getActiveUser().userId,
      };

      await this.api
        .UpdateTanque(updateTanqueInput)
        .then(() => {
          // 2. Se cargan los documentos del tanque, si aplica.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'TANQUE',
            this.tanqueId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp
          );

          // 3. Se actualiza el estado del acoplamiento, si aplica.
          const selectedTanque: Tanque = this.selectedTanque!;
          if (selectedTanque.tanqueCoupleCoupleId) {
            this.api.UpdateCouple({
              coupleId: selectedTanque.tanqueCoupleCoupleId,
              status: `${this.business}_${appConstants.entity.codes.toBeApproved}`,
            });
          }
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al actualizar tanque ${this.tanqueId}`
          );
        });
    } else {
      // Si es un flujo de archivado,
      // Se elimina primero la entidad anterior
      // para evitar conflictos si es la misma patente.
      if (this.archiveMode) {
        // 1. Eliminar entidad anterior
        // (en este punto sigue como la que está seleccionada)
        await this.tanquesService.deleteSelectedTanque(false);

        // 2. Liberamos el modo de archivado
        this.archivesService.setArchiving(false);
      }

      // En el modo crear los ID se toman desde el formulario.
      this.tanqueId = newTanque.licensePlate.toUpperCase();

      this.feedbacksService.showFeedback(
        `Creando tanque: ${this.tanqueId}`,
        'info'
      );

      // En modo creación o archivo:
      // 1. Se crea una nueva tanque.
      const createTanqueInput: CreateTanqueInput = {
        tanqueId: this.tanqueId,
        brand: newTanque.brand,
        licensePlate: this.tanqueId, // La licencia corresponde con el ID
        color: newTanque.color,
        model: newTanque.model.toUpperCase(),
        year: newTanque.year,
        type: newTanque.type,
        capacity: newTanque.capacity,
        business: this.business,
        subBusiness: newTanque.subBusiness,
        center: newTanque.center,
        originalCenter: newTanque.center,
        blockingMotives: [],
        gpsImei: newTanque.gpsImei,
        cameraImei: newTanque.cameraImei,
        forMining: newTanque.forMining === 'true',
        hasRanchAccreditation: newTanque.hasRanchAccreditation === 'true',
        isSpot: newTanque.isSpot === 'true',
        bulkheadType: newTanque.bulkheadType,
        hasPump: newTanque.hasPump === 'true',
        height: newTanque.height,
        width: newTanque.width,
        length: newTanque.length,
        status: `${this.business}_${appConstants.entity.codes.toBeApproved}`, // Un nuevo tanque debe ser aprobado
        company: newTanque.carrier,
        carrierTanquesCarrierId: newTanque.carrier,
        MOPCode: newTanque.MOPCode,
        updatedBy: this.usersService.getActiveUser().userId,
        ...(this.archiveMode && {
          archiveId: this.archivesService.getArchiveIdForEntity(),
        }),
      };
      console.log('createTanqueInput', createTanqueInput);

      this.api
        .CreateTanque(createTanqueInput)
        .then((tanque: CreateTanqueMutation) => {
          this.feedbacksService.showFeedback(
            `Tanque ${tanque.tanqueId} creado correctamente`,
            'success'
          );

          // 2. Se cargan los documentos del tanque.
          this.documentsService.uploadOrCopyDocuments(
            this.usersService.business.value,
            'TANQUE',
            this.tanqueId,
            documentList,
            uploadDateYYYYMMDD,
            uploadDateTimestamp,
            this.archiveMode
          );
        })
        .catch((response: any): void => {
          this.feedbacksService.showErrorFeedbacks(
            response,
            `Error al crear tanque ${this.tanqueId}`
          );
        });
    }

    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate back'));
  }

  /**
   * Notifica la edición de una cisterna, si aplica.
   * @param {string} uploadDateTimestamp Timestamp de edición.
   */
  private async notifyEdition(uploadDateTimestamp: string): Promise<void> {
    // Si el tanque editada viene de un estado "Disponible", "Rechazado" o "Bloqueado"
    // se notifica que fue editado
    const selectedTanque: Tanque = this.selectedTanque!;
    if (
      selectedTanque.status.endsWith(appConstants.entity.codes.rejected) ||
      selectedTanque.status.endsWith(appConstants.entity.codes.blocked) ||
      selectedTanque.status.endsWith(appConstants.entity.codes.available)
    ) {
      let initialStatus: string;
      let messageInitialStatus: string;
      if (selectedTanque.status.endsWith(appConstants.entity.codes.rejected)) {
        initialStatus = 'FROMREJECTED';
        messageInitialStatus = 'rechazado';
      } else if (
        selectedTanque.status.endsWith(appConstants.entity.codes.available)
      ) {
        initialStatus = 'FROMAVAILABLE';
        messageInitialStatus = 'disponible';
      } else {
        initialStatus = 'FROMBLOCKED';
        messageInitialStatus = 'bloqueado';
      }
      const creatNotificationInput: CreateNotificationInput = {
        businessId: selectedTanque.business,
        notificationId:
          'TANQUE#' + selectedTanque.tanqueId + '#' + uploadDateTimestamp,
        status: 'TO_BE_SENT',
        model: 'TANQUE',
        motive: `UPDATE#${initialStatus}#TOTOBEAPPROVED`,
        tanqueId: selectedTanque.tanqueId,
      };

      await this.api
        .CreateNotification(creatNotificationInput)
        .then(async () => {
          let message = 'Se ha enviado una notificación por la actualización ';
          message += `del tanque ${this.tanqueIdentification} previamente ${messageInitialStatus}.`;
          this.feedbacksService.showFeedback(message, 'info');
        });
    }
  }

  /**
   * Navega al detalle del tanque.
   */
  onCancel(): void {
    // Si el usuario cancela, se termina el flujo de archivado.
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
    // Regresamos al detalle del tanque.
    this.router
      .navigate(['../'], { relativeTo: this.route })
      .then(() => console.log('navigate to details'));
  }

  /* --------------------------------------- */
  /* - Métodos para ambiente de desarrollo - */
  /* --------------------------------------- */
  /**
   * Muestra el formulario en la consola.
   * Nota: Solo aparece en DEV.
   */
  showForm(): void {
    console.log('-- Current Form --');
    console.log(this.tanqueForm);
  }

  /**
   * Determina si se está en el negocio Externos para remover
   * controles del formulario.
   */
  isForeign(): boolean {
    let isForeign = this.business === 'FOREIGN';
    if (isForeign) {
      const notRequiredControls: string[] = [
        'subBusiness',
        'height',
        'width',
        'length',
      ];

      notRequiredControls.forEach((controlName: string) => {
        const control = this.tanqueForm.get(controlName);
        control?.removeValidators(Validators.required);
        control?.updateValueAndValidity();
      });
    }
    this.isNotForeign = !isForeign;
    return isForeign;
  }

  /**
   * Verifica el valor de un atributo para retornar nulo o el valor del atributo.
   * @param {number | null | undefined} attributeValue Valor del atributo.
   * @return {number | null | undefined} El valor del atributo, si es distinto de cero, o nulo, en caso contrario.
   * @private
   */
  private checkNumberValue(
    attributeValue: number | null | undefined
  ): number | null | undefined {
    return attributeValue === 0 ? null : attributeValue;
  }

  async ngOnDestroy(): Promise<void> {
    if (this.editionId !== '') {
      await this.editionsService.releaseEdition(this.editionId);
    }
    if (this.archiveMode) {
      this.archivesService.setArchiving(false);
    }
  }
}
