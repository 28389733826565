import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommercialAgreementsRoutingModule } from './commercial-agreements-routing-module';
import { DataTablesModule } from 'angular-datatables';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommercialAgreementsComponent } from './commercial-agreements.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadComponent } from './modals/upload/upload.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MasterModule } from '../master/master.module';
import { UpdateComponent } from './modals/update/update.component';
import { CreateComponent } from './modals/create/create.component';

@NgModule({
  declarations: [
    CommercialAgreementsComponent,
    UploadComponent,
    UpdateComponent,
    CreateComponent,
  ],
  imports: [
    CommonModule,
    CommercialAgreementsRoutingModule,
    SharedModule,
    DataTablesModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    MasterModule,
  ],
})
export class CommercialAgreementsModule {}
