<div class="container">
  <div class="modal-header">
    <h4 class="modal-title">
        Actualizar configuración del {{ selectedCenter }}
    </h4>
    <button
      id="header-excel-modal-close"
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="d('Cross')"
    ></button>
  </div>
  <div class="modal-body">
    <p>Se actualizarán las configuraciones del {{ selectedCenter }}</p>

    <p><strong>¿Desea replicar la configuración a otro centro?</strong></p>

    <div class="centers-title">
      <span class="material-symbols-outlined"> warehouse </span>
      <label>Centros</label>
    </div>

    <form [formGroup]="form">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-4">
            <label for="selectAll" class="form-label">
              <input
                type="checkbox"
                id="selectAll"
                formControlName="selectAll"
              />
              Seleccionar todos</label
            >
          </div>
        </div>
        <div class="row border-top py-2" formArrayName="centers">
          <div
            *ngFor="
              let centerControl of centersControls.controls;
              let i = index
            "
            [formGroupName]="i"
            class="col-sm-4"
          >
            <label class="form-label">
              <input
                type="checkbox"
                formControlName="selected"
                (change)="toggleIndividual()"
              />
              {{ centerControl.value.center.valueToDisplay }}</label
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      id="header-excel-modal-cancel"
      type="button"
      class="btn btn-outline-danger"
      (click)="d('Cancel')"
    >
      Cancelar
    </button>
    <button
      id="header-excel-modal-accept"
      type="button"
      class="btn btn-outline-primary"
      (click)="c('Accept')"
      [disabled]="!form.valid"
    >
      Aceptar
    </button>
  </div>
</div>
